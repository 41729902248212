import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import Collapsible from "react-collapsible"
import styled from "styled-components"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import ImageUploadInput from "../../../../../_shared/components/FormItems/ImageUploadInput"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import useRestTable from "../../../../../_shared/hooks/usePaginatedRestResource"

import AppDateRangePicker from "../../../../../_shared/components/FormItems/AppDateRangePicker"

// ----------------------------------------------------------------------------

function _ShopHeroEditor(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const {
        className,
        values,
        onChange,
        onDelete,
        openItem,
        showTitle = true,
    } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [{ token }] = useStateValue()
    const [filters] = useRestTable("/storeden/filters", token)
    const [storedenCategories] = useRestTable("/storeden/categories", token)

    const [brands] = useRestTable("/storeden/brands", token)

    const brandOptions = useMemo(() => {
        return brands?.map((brand) => ({ label: brand.name, value: brand.uid }))
    }, [brands])

    const categoryOptions = useMemo(() => {
        const options = []

        storedenCategories?.forEach((c) => {
            options.push({
                label: ` ${c.name}`,
                value: c.uid,
                disabled: c.disabled,
            })

            storedenCategories
                ?.filter((category) => {
                    return category.parentUID === c.uid
                })
                ?.map((tier3Category) => {
                    options.push({
                        label: `${c.name} >> ${tier3Category.name}`,
                        value: tier3Category.uid,
                        disabled: tier3Category.disabled,
                    })
                })
        })

        options.sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [storedenCategories])

    const filterTargetEntityOptions = useMemo(() => {
        if (storedenCategories?.length) {
            const firstLevelCategories = storedenCategories
            // Only get those categories that are direct children of a world
            // ?.filter((c) =>
            //     worlds?.some(
            //         (w) => String(w.storedenId) === String(c.parentUID)
            //     )
            // )
            const options = [...firstLevelCategories].map((c) => {
                if (c.parentUID) {
                    // const parentWorld = worlds?.find(
                    //     (w) => String(w.storedenId) === String(c.parentUID)
                    // )
                    return {
                        label: `${c.name}`,
                        value: c.uid,
                    }
                } else {
                    return { label: c.name, value: c.storedenId }
                }
            })
            options.sort((a, b) => a.label.localeCompare(b.label))
            return options
        } else {
            return []
        }
    }, [storedenCategories])

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        values?.targetType && onChange("target")("")
    }, [values?.targetType])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleDelete() {
        onDelete && onDelete(values)
    }

    function getFilterKeyOptions() {
        const baseOptions =
            filters?.map((f) => ({ label: f.key, value: f.key })) ?? []
        return [{ label: "Tag", value: "tag" }, ...baseOptions]
    }

    function getFilterValueOptions(filter) {
        if (filter?.key) {
            const values = filters?.find((f) => f.key === filter.key)?.values
            return (
                values &&
                Object.values(values).map((v) => ({ label: v, value: v }))
            )
        } else return []
    }

    function renderFilterSelector() {
        return (
            <div className="filter-selector">
                <div className="filters">{renderFilters()}</div>
                <AppButton
                    className="add-filter"
                    uxType="secondary"
                    htmlType="button"
                    onClick={handleAddFilter}
                >
                    Aggiugni filtro
                </AppButton>
            </div>
        )
    }

    function renderFilters() {
        const filters =
            (Array.isArray(values?.filters) ? values?.filters : []) ?? []
        return filters?.map((filter) => (
            <div className="filter" key={filter.id}>
                <AppSelect
                    options={getFilterKeyOptions()}
                    placeholder="Chiave"
                    className="select first"
                    label="Campo filtro"
                    value={filter?.key}
                    onChange={handleUpdateFilterKey(filter)}
                />
                {filter?.key === "tag" ? (
                    <AppInput
                        value={filter?.value}
                        onChange={handleUpdateFilterValue(filter)}
                        label="Valore tag"
                        className="select"
                    />
                ) : (
                    <AppSelect
                        value={filter?.value}
                        placeholder="Valore"
                        className="select"
                        label="Valore filtro"
                        options={getFilterValueOptions(filter)}
                        onChange={handleUpdateFilterValue(filter)}
                    />
                )}
                <AppButton
                    className="delete-button"
                    uxType="delete"
                    htmlType="button"
                    onClick={handleDeleteFilter(filter)}
                >
                    Elimina
                </AppButton>
            </div>
        ))
    }

    function handleAddFilter() {
        const handler = onChange("filters")
        const prevFilters = Array.isArray(values.filters) ? values.filters : []
        handler &&
            handler([
                ...(prevFilters ?? []),
                {
                    key: "",
                    value: "",
                    id: Math.random().toString(),
                },
            ])
    }

    function handleUpdateFilterValue(filter) {
        return (value) => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.map((f) =>
                f.id === filter?.id ? { ...f, value: value } : f
            )
            handler(newFilters)
        }
    }

    function handleUpdateFilterKey(filter) {
        return (value) => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.map((f) =>
                f.id === filter?.id ? { ...f, key: value, value: "" } : f
            )
            handler(newFilters)
        }
    }

    function handleDeleteFilter(filter) {
        return () => {
            const handler = onChange("filters")
            const newFilters = values?.filters?.filter(
                (f) => f.id !== filter?.id
            )
            handler(newFilters ?? [])
        }
    }

    function getTargetOptions() {
        switch (values?.targetType) {
            // case "world":
            //     return worldOptions
            case "category":
                return categoryOptions
            case "editorial":
                return []
            case "brand":
                return brandOptions
            case "filter":
                return filterTargetEntityOptions
            default:
                return []
        }
    }

    function getTargetFilterOption() {
        if (values?.targetType === "brand") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        if (values?.targetType === "category") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        return undefined
    }

    function renderTargetTypeSelector() {
        switch (values?.targetType) {
            case "filter": {
                return (
                    <>
                        <AppSelect
                            value={values?.target}
                            onChange={onChange("target")}
                            options={getTargetOptions()}
                            className="input"
                            placeholder="Seleziona target"
                            label="Target"
                            allowClear
                        />
                        {renderFilterSelector()}
                    </>
                )
            }

            case "tag": {
                return (
                    <AppInput
                        label="Tag"
                        value={values?.target}
                        onChange={onChange("target")}
                        className="input"
                    ></AppInput>
                )
            }

            default: {
                return (
                    <AppSelect
                        value={values?.target}
                        onChange={onChange("target")}
                        options={getTargetOptions()}
                        filterOption={getTargetFilterOption()}
                        className="input"
                        placeholder="Seleziona target"
                        label="Target"
                    />
                )
            }
        }
    }

    function getViewingPeriod() {
        const period = values?.viewingPeriod ?? {}
        const { start, end } = period
        return start && end ? [moment(start), moment(end)] : null
    }

    function handleViewingPeriodChange(newValues) {
        const handler = onChange("viewingPeriod")

        if (newValues) {
            const [start, end] = newValues.map((date) => {
                return date
            })

            handler({ start, end })
        } else {
            handler(null)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const destinationOptions = [
        { label: "Categoria", value: "category" },
        { label: "Brand", value: "brand" },
        { label: "Tag", value: "tag" },
    ]

    function returnItem() {
        return (
            <div>
                {showTitle && (
                    <AppInput
                        className="input"
                        value={values?.title}
                        onChange={onChange("title")}
                        label="Titolo"
                    />
                )}
                <AppDateRangePicker
                    label="Periodo di visualizzazione"
                    className="input"
                    value={getViewingPeriod()}
                    onChange={handleViewingPeriodChange}
                />
                <AppSelect
                    value={values?.targetType}
                    onChange={onChange("targetType")}
                    options={destinationOptions}
                    className="input"
                    placeholder="Seleziona categoria"
                    label="Categoria"
                />

                {renderTargetTypeSelector()}

                <ImageUploadInput
                    image={values?.image}
                    onUpload={onChange("image")}
                />
                <AppButton onClick={() => handleDelete(values.id)}>
                    Elimina area
                </AppButton>
            </div>
        )
    }

    return <div className={`${className}`}>{returnItem()}</div>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_ShopHeroEditor.propTypes = {
    className: PropTypes.string.isRequired,
    values: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
}

_ShopHeroEditor.defaultProps = {}

// ----------------------------------------------------------------------------

const ShopHeroEditor = styled(_ShopHeroEditor)`
    & {
        .delete-btn {
            margin-top: 40px;
            width: 100%;
            height: 48px;
            /* max-width: unset; */
        }
        .add-btn {
            margin-top: 40px;
            width: 80%;
            height: 48px;
            border: 2px solid darkgreen;
            background-color: green;
            /* max-width: unset; */
        }
        .input {
            margin-bottom: 20px;
            max-width: 300px;
        }
        .color-picker {
            width: 250px;
            margin-bottom: 20px;
            .tag {
                border: 1px solid black;
            }
        }

        .row {
            display: flex;
            overflow-x: auto;
            padding: 20px;
        }
        .item {
            margin-top: 3px;
            margin-right: 20px;
            border: none;
            padding: 4px;
        }
        .visibile-button {
            height: 25px;
            width: 30px;
            background-color: transparent;
        }
    }
`
// ----------------------------------------------------------------------------

export default ShopHeroEditor
