import { DatePicker } from "antd"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"

const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

function _AppDateRangePicker(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, value, onChange, label } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [focused, setIsFocused] = useState("")
    const pickerRef = useRef()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFocus() {
        setIsFocused(true)
    }

    function handleBlur() {
        setIsFocused(false)
    }

    function getStateClass() {
        let cssClass = ""
        if (focused) {
            cssClass = "focused"
        } else {
            cssClass = value ? "valid" : ""
        }

        return cssClass
    }

    function handleChange(e) {
        onChange && onChange(e)
    }

    function handleLabelClick() {
        pickerRef?.current?.focus()
        console.log(pickerRef?.current)
    }

    const onChange2 = (value, dateString) => {
        handleChange(dateString)
    }
    const onOk = (value) => {}

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className} ${getStateClass()}`}>
            <label className="input-label">{label}</label>
            {/* <RangePicker
                ref={pickerRef}
                className="nograv-picker"
                value={value}
                onChange={handleChange}
                placeholder=""
                onFocus={handleFocus}
                onBlur={handleBlur}
                showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                        dayjs("00:00:00", "HH:mm:ss"),
                        dayjs("11:59:59", "HH:mm:ss"),
                    ],
                }}
                format="YYYY-MM-DD HH:mm:ss"
            /> */}
            <RangePicker
                placeholder=""
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="nograv-picker"
                format="YYYY-MM-DD HH:mm"
                onChange={onChange2}
                onOk={onOk}
                value={value}
                showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                        dayjs("00:00", "HH:mm"),
                        dayjs("11:59", "HH:mm"),
                    ],
                }}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppDateRangePicker.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
}

_AppDateRangePicker.defaultProps = {}

// ----------------------------------------------------------------------------

const AppDateRangePicker = styled(_AppDateRangePicker)`
    & {
        position: relative;
        .ant-picker {
            width: 110%;
            max-width: 400px;
            height: 48px;
            border-radius: 6px;
            border: 1px solid ${({ theme }) => theme.colors.inputBorder};
            box-shadow: none !important;
            padding: 0 10px 0 10px;
            font-size: 14px;

            .ant-picker-range-separator {
                display: none;
            }

            .ant-picker-active-bar {
                background: black;
            }

            .ant-picker-input {
                padding-top: 8px;
                input {
                }
                .ant-picker-clear {
                    padding-top: 8px;
                }
            }

            &.ant-picker-focused {
            }
        }
        .input-label {
            position: absolute;
            left: 10px;
            top: calc(50%);
            transform: translateY(-50%);
            transition: all 200ms ease;
            color: ${({ theme }) => theme.colors.inputBorder};
            z-index: 1;
            pointer-events: none;
        }
        &.valid,
        &.focused {
            .ant-picker {
                border: 1px solid ${({ theme }) => theme.colors.primary};
                .ant-picker-input {
                    input {
                    }
                }
                .ant-picker-range-separator {
                    display: unset;
                }
            }
            .input-label {
                top: 10px;
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 700;
                font-size: 10px;

                /* transform: translateY(0%); */
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppDateRangePicker
