import { Switch } from "antd"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

// ----------------------------------------------------------------------------

function _AppSwitch(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { label, value, onChange, disabled } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            {label && <div className="label">{label}</div>}
            <Switch
                checked={value}
                onChange={onChange && onChange}
                disabled={disabled}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppSwitch.propTypes = {
    className: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

_AppSwitch.defaultProps = {}

// ----------------------------------------------------------------------------

const AppSwitch = styled(_AppSwitch)`
    & {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .label {
            display: block;
            font-size: 16px;
            line-height: 16px;
            color: ${({ theme }) => theme?.colors?.textMuted};
        }
        .ant-switch {
            outline: none;
        }
        .ant-switch-checked {
            background: ${({ theme }) => theme.colors.primary};
        }
    }
`
// ----------------------------------------------------------------------------

export default AppSwitch
