import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Popconfirm, Tag } from "antd"
import dayjs from "dayjs"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import PostFormBottomSheet from "./PostFormBottomSheet"

// ----------------------------------------------------------------------------

function Blog(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [
        requests,
        fetchPosts,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource("/posts", token)

    const [selectedPost, setSelectedPost] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleCreatePost(post) {
        try {
            let body = {
                ...post,
            }

            if (post.image) {
                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.createResource("/posts", token, body)
            setSelectedPost(null)
            fetchPosts()
            message.success("Articolo creato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdatePost(post) {
        try {
            let body = {
                ...post,
            }

            if (post.image && typeof post.image !== "string") {
                const oldImage = requests?.find(
                    (item) => item?.id === post.id
                )?.image

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                await api.deleteStoredenFile(imageId, token)

                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.updateResource(`/posts`, token, selectedPost.id, body)
            setSelectedPost(null)
            fetchPosts()
            message.success("Articolo aggiornato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handlePostBottomSheetConfirm(post) {
        if (post.id) {
            return handleUpdatePost(post)
        } else {
            return handleCreatePost(post)
        }
    }

    async function handleDeletePost(post) {
        try {
            if (!post?.id) {
                return
            }

            await api.deleteResource("/posts", token, post.id)
            fetchPosts()

            message.success("Articolo eliminato con successo")
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handlePostBottomSheetCancel() {
        setSelectedPost(null)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => setSelectedPost(row)} />

                <Popconfirm
                    onConfirm={() => handleDeletePost(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo articolo?"
                >
                    <DeleteButton />
                </Popconfirm>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const defaultPost = {
        published: false,
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Articoli</h1>

            <AppButton
                uxType="secondary"
                onClick={() => setSelectedPost(defaultPost)}
            >
                Nuovo articolo
            </AppButton>

            <Table
                dataSource={requests}
                pagination={{ ...pagination, total: totalRecordsNumber }}
                onChange={(pagination) => fetchPosts({ pagination })}
                columns={getColumns(renderActions)}
                loading={loading}
            />

            <PostFormBottomSheet
                open={selectedPost ? true : false}
                post={selectedPost}
                onCancel={handlePostBottomSheetCancel}
                onConfirm={handlePostBottomSheetConfirm}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Blog.propTypes = {
    className: PropTypes.string.isRequired,
}

Blog.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledBlog = styled(Blog)`
    & {
        .actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledBlog

function getColumns(renderActions) {
    return [
        {
            title: "Titolo",
            key: "title",
            dataIndex: "title",
        },
        {
            title: "Data",
            key: "date",
            dataIndex: "date",
            render: (v) => dayjs(v).format("D MMM YYYY HH:mm"),
        },
        {
            title: "Stato",
            key: "published",
            dataIndex: "published",
            // eslint-disable-next-line
            render: (published) =>
                published ? (
                    <Tag color="green">PUBBLICATO</Tag>
                ) : (
                    <Tag color="orange">BOZZA</Tag>
                ),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
