import PropTypes from "prop-types"
import React from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"

import Logo from "../../../images/logo-white.svg"

import { useStateValue } from "../../../context/AppStateStore"

// ----------------------------------------------------------------------------

function _Menu(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [, dispatch] = useStateValue()
    const location = useLocation()
    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function getRouteActive(route, exact) {
        if (exact) return route === location.pathname
        else {
            const pat = new RegExp(route)
            return pat.test(location.pathname)
        }
    }

    function renderRoute({
        path,
        name,
        // icon,
        exact,
    }) {
        const isActive = getRouteActive(path, exact)
        return (
            <div className={`route ${isActive ? "active" : ""}`}>
                <Link className="link" to={path}>
                    {name}
                </Link>
            </div>
        )
    }

    function renderRoutes() {
        return (
            <div className="routes">
                {/* {renderRoute({ name: "Mondi", path: "/", exact: true })} */}
                {/* {renderRoute({
                    name: "Sconti",
                    path: "/discount",
                    exact: false,
                })}*/}

                {renderRoute({ name: "CMS", path: "/cms", exact: false })}
                {/* {renderRoute({
                    name: "Account cancellati",
                    path: "/account-delete-requests",
                    exact: true,
                })} */}
                {renderRoute({
                    name: "Blog",
                    path: "/blog",
                    exact: false,
                })}
                {renderRoute({
                    name: "Categorie",
                    path: "/categories",
                    exact: false,
                })}
                {renderRoute({
                    name: "Coupon",
                    path: "/coupons",
                    exact: false,
                })}
                {renderRoute({
                    name: "Spedizione gratuita",
                    path: "/shipping",
                    exact: false,
                })}
                {renderRoute({
                    name: "Stores",
                    path: "/stores",
                    exact: false,
                })}
                {renderRoute({
                    name: "Impostazioni",
                    path: "/settings",
                    exact: false,
                })}

                {renderRoute({
                    name: "Notifiche",
                    path: "/notifications",
                    exact: false,
                })}

                {renderRoute({
                    name: "Totem punti vendita",
                    path: "/totemsStore",
                    exact: false,
                })}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <img src={Logo} className="logo" height={300} />

            {renderRoutes()}
            <div
                className="signout"
                onClick={() => dispatch({ type: "SIGN_OUT" })}
            >
                Signout
            </div>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Menu.propTypes = {
    className: PropTypes.string.isRequired,
}

_Menu.defaultProps = {}

// ----------------------------------------------------------------------------

const Menu = styled(_Menu)`
    & {
        width: 250px;
        height: 100vh;
        background: ${(props) => props.theme.colors.primary};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .logo {
            margin-top: 50px;
            width: 80%;
            height: auto;
        }
        .routes {
            margin-top: 50px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px 30px;
            .route {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid white;
                border-radius: 8px;

                transition: all 200ms ease;
                cursor: pointer;
                margin-bottom: 15px;
                .link {
                    padding: 6px 0px;
                    color: white;
                    transition: all 200ms ease;
                    font-weight: 500;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &.active {
                    background-color: white;
                    .link {
                        color: black;
                    }
                }
                &:hover {
                    background-color: white;
                    .link {
                        color: black;
                    }
                }
            }
        }
        .signout {
            margin-top: auto;
            color: white;
            margin-bottom: 30px;
            cursor: pointer;
        }
    }
`
// ----------------------------------------------------------------------------

export default Menu
