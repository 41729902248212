import { Checkbox, DatePicker } from "antd"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"

const { RangePicker } = DatePicker

// ----------------------------------------------------------------------------

function _AppCheckBox(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onChange, children, checked = false } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [focused, setIsFocused] = useState("")
    const pickerRef = useRef()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Checkbox onChange={onChange} checked={checked}>
            {children}
        </Checkbox>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AppCheckBox.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
}

_AppCheckBox.defaultProps = {}

// ----------------------------------------------------------------------------

const AppCheckBox = styled(_AppCheckBox)`
    & {
        position: relative;
        .ant-picker {
            width: 110%;
            max-width: 400px;
            height: 48px;
            border-radius: 6px;
            border: 1px solid ${({ theme }) => theme.colors.inputBorder};
            box-shadow: none !important;
            padding: 0 10px 0 10px;
            font-size: 14px;

            .ant-picker-range-separator {
                display: none;
            }

            .ant-picker-active-bar {
                background: black;
            }

            .ant-picker-input {
                padding-top: 8px;
                input {
                }
                .ant-picker-clear {
                    padding-top: 8px;
                }
            }

            &.ant-picker-focused {
            }
        }
        .input-label {
            position: absolute;
            left: 10px;
            top: calc(50%);
            transform: translateY(-50%);
            transition: all 200ms ease;
            color: ${({ theme }) => theme.colors.inputBorder};
            z-index: 1;
            pointer-events: none;
        }
        &.valid,
        &.focused {
            .ant-picker {
                border: 1px solid ${({ theme }) => theme.colors.primary};
                .ant-picker-input {
                    input {
                    }
                }
                .ant-picker-range-separator {
                    display: unset;
                }
            }
            .input-label {
                top: 10px;
                color: ${({ theme }) => theme.colors.primary};
                font-weight: 700;
                font-size: 10px;

                /* transform: translateY(0%); */
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default AppCheckBox
