import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import Switch from "../../../../_shared/components/AppSwitch"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"
import Select from "../../../../_shared/components/FormItems/AppSelect"
import AppTextarea from "../../../../_shared/components/FormItems/AppTextarea"
import useForm from "../../../../_shared/hooks/useForm"

// ----------------------------------------------------------------------------

function DiscountBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, discount, onConfirm, ...sheetProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialValues = discount ?? {}

    const [formValues, setFormValue, setFormValues, clearForm] =
        useForm(initialValues)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (open) {
            setFormValues(discount ?? {})
        } else {
            clearForm()
        }
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    function handleConfirm() {
        onConfirm?.(formValues)
    }

    function getOptions() {
        return [
            {
                value: "percentage",
                label: "Percentuale",
            },
            {
                value: "amount",
                label: "Importo",
            },
        ]
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <BottomSheet
            className={props.className}
            open={open}
            snapPoint={80}
            title={discount?.id ? "Modifica sconto" : "Nuovo sconto"}
            onConfirm={handleConfirm}
            {...sheetProps}
        >
            <Switch
                className="form-control"
                label="Abilitato"
                value={formValues?.enabled}
                onChange={handleFormValueChange("enabled")}
            />

            <Input
                className="form-control"
                label="Nome dello sconto"
                value={formValues.name}
                onChange={handleFormValueChange("name")}
            />

            <Input
                className="form-control"
                label="Valore"
                value={formValues.value}
                onChange={handleFormValueChange("value")}
            />

            <AppTextarea
                className="form-control"
                label="Descrizione"
                value={formValues.description}
                onChange={handleFormValueChange("description")}
            />

            <Select
                className="form-control"
                label="Tipo"
                value={formValues.type}
                onChange={handleFormValueChange("type")}
                options={getOptions()}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

DiscountBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    discount: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
}

DiscountBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const DiscountBottomSheetWithStyle = styled(DiscountBottomSheet)`
    & {
        .sheet-content {
            max-width: 500px;

            .form-control {
                margin-bottom: 20px;
                width: 500px;
                max-width: 500px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default DiscountBottomSheetWithStyle
