import PropTypes from "prop-types"
import React from "react"
import { Link, Route, Switch, useLocation } from "react-router-dom"
import styled from "styled-components"

import Home from "./Home"
import Shop from "./Shop"

import TopMessage from "./TopMessage"

// ----------------------------------------------------------------------------

function _Cms(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const { pathname } = useLocation()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderRoutes() {
        return (
            <div className="routes">
                <Link className="route" to="/cms/home">
                    Widget Pagina Home
                </Link>
                <Link className="route" to="/cms/shop">
                    Widget Pagina Shop
                </Link>
                <Link className="route" to="/cms/top-message">
                    Top Message
                </Link>
            </div>
        )
    }

    function getActiveRouteName() {
        switch (pathname) {
            case "/cms":
                return ""
            case "/cms/home":
                return "- Home"
            case "/cms/shop":
                return "- Shop"
            case "/cms/top-message":
                return "- Top Message"
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="page-title">CMS {getActiveRouteName()}</h1>
            <Switch>
                <Route exact path="/cms" component={renderRoutes} />
                <Route path="/cms/home" component={Home} />
                <Route path="/cms/shop" component={Shop} />
                <Route path="/cms/top-message" component={TopMessage} />
            </Switch>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Cms.propTypes = {
    className: PropTypes.string.isRequired,
}

_Cms.defaultProps = {}

// ----------------------------------------------------------------------------

const Cms = styled(_Cms)`
    & {
        .routes {
            width: 100%;
            .route {
                height: 50px;
                width: 100%;
                padding-left: 10px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
                color: ${({ theme }) => theme.colors.textPrimary};
                transition: all 200ms ease;
                font-size: 16px;

                &:hover {
                    background-color: ${({ theme }) => theme.colors.grey200};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Cms
