import React, { useEffect, useMemo, useState } from "react"
import { Popconfirm, Select, Table, message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useStateValue } from "../../../../_shared/context/AppStateStore"

import AppButton from "../../../../_shared/components/AppButton"
import * as api from "../../../../../api/Local"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import EditButton from "../../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../../_shared/components/Buttons/DeleteButton"
import useRestTable from "../../../../_shared/hooks/usePaginatedRestResource"

// ----------------------------------------------------------------------------

function _HiddenBrands(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const [{ token }] = useStateValue()

    const [hiddenBrandsForm, setHiddenBrandsForm] = useState([])
    const [defaultValues, setDefaultValues] = useState([])

    const slug = getSlug()

    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")
        return splitArray[splitArray?.length - 2]
    }
    const [
        hiddenBrands,
        fetchHiddenBrands,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource(`totemstore/${slug}/hiddenBrands`, token)

    const [brands] = useRestTable("/storeden/brands", token)
    console.log("hidden brands : ", hiddenBrands)

    useEffect(() => {
        fetchHiddenBrands()
    }, [])

    useEffect(() => {
        setHiddenBrandsForm(hiddenBrands)
    }, [hiddenBrands])
    const [selectedItem, setSelectedItem] = useState()

    const { className } = props

    const handleChange = (value) => {
        console.log("handle change value", value)
        setHiddenBrandsForm(value)
    }

    async function handleConfirm(formValues) {
        try {
            const storeView = await api.getTotemStore(token, slug)

            const newStoreView = {
                ...storeView,
                hiddenBrands: hiddenBrandsForm,
            }
            console.log("newStoreView : ", newStoreView)

            await api.updateTotemStore(slug, newStoreView, token)
            fetchHiddenBrands()
            message.success("Operazione completata con successo")
            // setSelectedItem(null)
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }
    const brandOptions = useMemo(() => {
        return brands?.map((brand) => ({
            label: brand.name,
            value: String(brand.uid),
        }))
    }, [brands])

    return (
        <div className={`${className}`}>
            <h1 className="page-title">I tuoi marchi nascosti</h1>

            {!loading && (
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", marginBottom: 100 }}
                    placeholder="Please select"
                    value={hiddenBrandsForm}
                    onChange={handleChange}
                    options={brandOptions}
                    optionFilterProp="label"
                />
            )}
            <AppButton
                onClick={handleConfirm}
                uxType="primary"
                htmlType="submit"
            >
                Salva
            </AppButton>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HiddenBrands.propTypes = {
    className: PropTypes.string.isRequired,
}

_HiddenBrands.defaultProps = {}

// ----------------------------------------------------------------------------

const HiddenBrands = styled(_HiddenBrands)`
    & {
        .stats-container {
            margin: 20px 0;
            padding: 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .actions {
            flex-direction: "row";
            display: flex;
        }
        .available-for-list {
            span {
                display: block;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default HiddenBrands

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
