import React, { useState } from "react"
import { Modal, PageHeader, message } from "antd"
import PropTypes, { func } from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import * as api from "../../../../api/Local"

import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import useRestResource from "../../../_shared/hooks/useRestResource"

import Table from "../../../_shared/components/Table"
import TableActions from "../../../_shared/components/TableActions"
import { useStateValue } from "../../../_shared/context/AppStateStore"

import {
    LikeOutlined,
    SendOutlined,
    CalendarOutlined,
    FieldTimeOutlined,
} from "@ant-design/icons"

import NotificationsBottomSheet from "./NotificationsBottomSheet"

// ----------------------------------------------------------------------------

function _Templates(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [notifications, fetchNotifications] = usePaginatedRestResource(
        "/notifications",
        token
    )

    console.log("token : ", token)

    console.log("notifications : ", notifications)

    const [, , , , deviceCount] = usePaginatedRestResource("/devices", token)

    const [selectedItem, setSelectedItem] = useState()
    const [openModal, setOpenModal] = useState()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function renderActions(row) {
        return (
            <TableActions
                onSend={() => handleSend(row)}
                onEdit={() => setSelectedItem(row)}
                onDetails={() => {
                    setOpenModal(row)
                }}
                onDelete={() => handleDelete(row)}
                devicesNumber={deviceCount}
            />
        )
    }

    async function handleConfirm(formValues) {
        try {
            if (formValues?.id) {
                await api.updateNotification(formValues?.id, formValues, token)
            } else {
                await api.createNotification(formValues, token)
            }

            fetchNotifications()
            message.success("Operazione completata con successo")
            setSelectedItem(null)
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }

    async function handleDelete(notitication) {
        try {
            await api.deleteNotification(notitication?.id, token)
            fetchNotifications()
            message.success("Operazione completata con successo")
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }

    async function handleSend(notitication) {
        try {
            await api.publishNotification(notitication?.id, token)
            fetchNotifications()
            message.success("Operazione completata con successo")
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }

    function handleCancel() {
        setSelectedItem(null)
    }

    function handleNewNotificationClick() {
        setSelectedItem({})
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    function getMinutes(views, publishtime) {
        let sum = 0

        views?.map((view) => {
            const first = new Date(view?.dateTime)
            const second = new Date(publishtime)

            var diffMs = first - second
            var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000)
            sum += diffMins
        })

        return sum / views.length
    }

    function getColorPercentage(value) {
        if (value > 0.75) return "green"

        if (value < 0.4) return "red"
        return "orange"
    }

    function getColorTime(value) {
        console.log("value : ", value)
        if (value < 10) return "#52c41a"

        return "#faad14"
    }
    return (
        <div className={`${className}`}>
            <h1 className="page-title">Notifiche</h1>
            <div className="stats-container">
                <Statistic
                    title="Quanti dispositivi riceveranno la notifica?"
                    value={deviceCount}
                />
            </div>
            <Modal
                open={openModal}
                footer={null}
                bodyStyle={{ borderRadius: 20 }}
                width={"50%"}
                onCancel={() => setOpenModal()}
            >
                <PageHeader
                    style={{ alignSelf: "center" }}
                    title="Statistiche notifica"
                    className="site-page-header"
                    subTitle={`${openModal?.title}`}
                >
                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Card
                            // title="Card title"
                            title="Inviata in data"
                            style={{
                                borderWidth: 1.5,
                                borderRadius: 10,
                                marginRight: 20,
                                width: "45%",
                            }}
                        >
                            <Statistic
                                style={{ marginBottom: 20 }}
                                value={new Date(
                                    openModal?.publishedAt
                                ).toLocaleString()}
                                // valueStyle={{ color: "#13c2c2" }}
                                prefix={
                                    <CalendarOutlined
                                        style={{
                                            marginRight: 10,
                                        }}
                                    />
                                }
                                // valueStyle={{ color: "red" }}
                            />
                        </Card>
                        <Card
                            title="Dispositivi che l'hanno ricevuta  "
                            style={{
                                borderWidth: 1.5,
                                borderRadius: 10,
                                marginRight: 20,
                                width: "45%",
                            }}
                        >
                            <Statistic
                                style={{ marginBottom: 20 }}
                                value={openModal?.devicesReached}
                                prefix={
                                    <SendOutlined
                                        style={{
                                            marginRight: 10,
                                        }}
                                    />
                                }
                            />
                        </Card>
                    </div>

                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: 15,
                        }}
                    >
                        <Card
                            // title="Card title"
                            title="Dispositivi che l'hanno aperta  "
                            style={{
                                borderWidth: 1.5,
                                borderRadius: 10,
                                marginRight: 20,
                                width: "45%",
                            }}
                        >
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "baseline",
                                }}
                            >
                                <Statistic
                                    style={{
                                        marginBottom: 20,
                                        marginRight: 10,
                                    }}
                                    value={`${openModal?.views?.length ?? 0}`}
                                    prefix={
                                        <LikeOutlined
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                    }
                                />
                                {openModal?.views?.length > 0 && (
                                    <Tag
                                        color={getColorPercentage(
                                            openModal?.views?.length /
                                                openModal?.devicesReached
                                        )}
                                        bordered={false}
                                        // style={{ height: 15 }}
                                    >
                                        {(openModal?.views?.length /
                                            openModal?.devicesReached) *
                                            100}
                                        %
                                    </Tag>
                                )}
                            </div>
                        </Card>

                        {openModal?.views?.length > 0 && (
                            <Card
                                title="Aperta mediamente dopo  "
                                style={{
                                    borderWidth: 1.5,
                                    borderRadius: 10,
                                    marginRight: 20,
                                    width: "45%",
                                }}
                            >
                                <Statistic
                                    valueStyle={{
                                        color: getColorTime(
                                            getMinutes(
                                                openModal?.views,
                                                openModal?.publishedAt
                                            )
                                        ),
                                    }}
                                    style={{ marginBottom: 20 }}
                                    value={`${getMinutes(
                                        openModal?.views,
                                        openModal?.publishedAt
                                    )} MIN`}
                                    prefix={
                                        <FieldTimeOutlined
                                            style={{ marginRight: 10 }}
                                        />
                                    }
                                />
                            </Card>
                        )}
                    </div>
                </PageHeader>
            </Modal>
            <Table
                onCreate={handleNewNotificationClick}
                dataSource={notifications}
                columns={getColumns(renderActions)}
            />
            <NotificationsBottomSheet
                defaultValues={selectedItem}
                open={!!selectedItem}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Templates.propTypes = {
    className: PropTypes.string.isRequired,
}

_Templates.defaultProps = {}

// ----------------------------------------------------------------------------

const Templates = styled(_Templates)`
    & {
        .stats-container {
            margin: 20px 0;
            padding: 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .available-for-list {
            span {
                display: block;
            }
        }

        .ant-page-header-heading {
            justify-content: "center";
        }
    }
`
// ----------------------------------------------------------------------------

export default Templates

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
            render: (published) =>
                published && <Tag color="green">PUBBLICATA</Tag>,
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
