import { Modal } from "antd"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"

import { getCmsVersion } from "../../../api/Local"
import Layout from "../../_shared/components/Layout"
import { useStateValue } from "../../_shared/context/AppStateStore"
import AccountDeleteRequests from "./AccountDeleteRequests"
import CMS from "./CMS"
import Discount from "./Discount"
import FreeShippingThreshold from "./FreeShippingThreshold"
import Settings from "./Settings"
import Blog from "./Blog"
import Categories from "./Categories"

import Stores from "./Stores"
import Notifications from "./Notifications"
import Totem from "./Totem"
import TotemsStore from "./TotemsStore"
import Coupons from "./Coupon"

import usePaginatedRestResource from "../../_shared/hooks/usePaginatedRestResource"
import { isThereNotificationToSend } from "../../_shared/utils"

// ----------------------------------------------------------------------------

function _LoggedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [notifications, fetchNotifications] = usePaginatedRestResource(
        "/notifications",
        token
    )

    useEffect(async () => {
        if (notifications) {
            const not = await isThereNotificationToSend(
                notifications,
                "2024-03-15T14:00:22.077Z"
            )
            console.log("not : ", not)
        }
    }, [notifications])
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        // eslint-disable-next-line
        setImmediate(async () => {
            if (token) {
                const version = await getCmsVersion(token).catch((e) => {
                    console.log(e)
                    return null
                })
                if (!version) {
                    Modal.warning({
                        title: "Non è stato possibile determinare la versione del CMS remoto.",
                        content: `
                        Questo potrebbe essere causato dal fatto che il CMS versionato non è mai stato esportato a Storeden.
                        Verifica che i mondi che devono essere mostrati in-app siano abilitati
                        (di default tutti i mondi sono nascosti) ed esporta il cms così che venga impostata una versione.
                        `,
                    })
                }
            }
        })
    }, [token])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <Layout className={`${className}`}>
            <Switch>
                <Route path="/cms" component={CMS} />
                {/* <Route path="/discount" component={Discount} />
                
                <Route
                    path="/account-delete-requests"
                    component={AccountDeleteRequests}
                /> */}
                <Route path="/blog" component={Blog} />
                <Route path="/categories" component={Categories} />
                <Route path="/coupons" component={Coupons} />

                <Route path="/stores" component={Stores} />
                <Route path="/settings" component={Settings} />
                <Route path="/shipping" component={FreeShippingThreshold} />
                <Route path="/notifications" component={Notifications} />
                <Route path="/totemsStore" component={TotemsStore} />
                <Route path="/totem" component={Totem} />
            </Switch>
        </Layout>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_LoggedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_LoggedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const LoggedApp = styled(_LoggedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default LoggedApp
