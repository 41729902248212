import { Modal, message } from "antd"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"

import { publishCMS } from "../../../../api/Local"
import { useStateValue } from "../../context/AppStateStore"

import usePaginatedRestResource from "../../hooks/usePaginatedRestResource"
import AppButton from "../AppButton"
import Menu from "./Menu"

// ----------------------------------------------------------------------------

function _Layout(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [{ token }] = useStateValue()
    const [submitting, setSubmitting] = useState(false)

    const [cmsSyncs] = usePaginatedRestResource("/cms-syncs", token, {
        pageSize: 1,
    })

    console.log(">>> cmsSyncs: ", cmsSyncs)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handlePublish() {
        setSubmitting(true)
        publishCMS(token)
            .then(() => message.success("Dati CMS pubblicati con successo"))
            .catch((e) => {
                console.log(e)
                message.error("Errore durante la pubblicazione dei dati.")
            })
            .finally(() => setSubmitting(false))
    }

    function confirm() {
        Modal.confirm({
            onOk: handlePublish,
            title: "Pubblicazione CMS",
            content:
                "Questa azione sovrascriverà il contenuto correntemente disponibile nell'app mobile. Sei sicuro di voler proseguire?",
            okText: "Sì, pubblica il CMS",
            cancelText: "Annulla",
        })
    }

    function renderLastPublishing() {
        const lastPublishing = cmsSyncs?.[0]?.updatedAt
        if (!lastPublishing) {
            return null
        }

        console.log("lastPublishing: ", lastPublishing)

        return (
            <span>
                Ultima pubblicazione{" "}
                {dayjs(lastPublishing)?.format("DD/MM/YYYY HH:mm")}
            </span>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <Menu />
            <main className="main-content">
                {
                    <div className="sync-button-container">
                        {renderLastPublishing()}
                        <AppButton
                            htmlType="button"
                            uxType="primary"
                            className="sync-button"
                            loading={submitting}
                            i={true}
                            onClick={confirm}
                        >
                            Pubblica CMS
                        </AppButton>
                    </div>
                }
                {props.children}
            </main>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Layout.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.object,
}

_Layout.defaultProps = {}

// ----------------------------------------------------------------------------

const Layout = styled(_Layout)`
    & {
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        background-color: ${(props) => props.theme.colors.primary};

        .main-content {
            flex: 1;
            padding: 20px;
            padding-top: 30px;
            overflow-y: auto;
            overflow-x: hidden;

            background-color: white;

            border-radius: 6px 0 0 6px;

            .sync-button-container {
                align-self: flex-end;
                position: absolute;
                right: 30px;

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                > span {
                    font-size: 11px;
                    color: rgba(0, 0, 0, 0.5);
                }
            }

            .sync-button {
            }

            .page-title {
                font-size: 24px;
                font-weight: 600;
                font-family: Ibm;
                margin-bottom: 30px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Layout
