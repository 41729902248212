import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Badge, Modal } from "antd"
import dayjs from "dayjs"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import CouponsFormBottomSheet from "./CouponsFormBottomSheet"
import StyledPopconfirm from "../../../_shared/components/Popconfirm"

import Papa from "papaparse"
import moment from "moment"

// ----------------------------------------------------------------------------

function Coupons(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [
        coupons,
        fetchCoupons,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource("/coupons", token)

    const [selectedCoupon, setSelectedCoupon] = useState(null)

    const [openSelectFile, setOpenSelectFile] = useState(null)
    const [importedCoupons, setImportedCoupons] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleCreateCoupon(post, id) {
        try {
            let body = {
                ...post,
            }
            console.log("body is : ", body)
            if (post.detailImage && typeof post.detailImage !== "string") {
                const oldImage = coupons?.find(
                    (item) => item?.id === post.id
                )?.detailImage

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                const uploadResponse = await api.uploadFile(post.detailImage)

                body.detailImage = uploadResponse?.url ?? body.detailImage
            }

            if (post.image && typeof post.image !== "string") {
                const oldImage = coupons?.find(
                    (item) => item?.id === post.id
                )?.image

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.createResource("/coupons", token, body)
            setSelectedCoupon(null)
            fetchCoupons()
            message.success("Coupon creato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdateCoupon(post, id) {
        try {
            let body = {
                ...post,
            }

            if (post.pdf && typeof post.pdf !== "string") {
                const oldImage = coupons?.find(
                    (item) => item?.id === post.id
                )?.pdf

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                const uploadResponse = await api.uploadFile(post.pdf)

                body.pdf = uploadResponse?.url ?? body.pdf
            }

            if (post.image && typeof post.image !== "string") {
                const oldImage = coupons?.find(
                    (item) => item?.id === post.id
                )?.image

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.updateResource(
                `/coupons`,
                token,
                id ?? selectedCoupon.id,
                body
            )
            setSelectedCoupon(null)
            fetchCoupons()
            message.success("Coupon aggiornato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleCouponBottomSheetConfirm(post) {
        console.log("post :", post)
        if (post.id) {
            return handleUpdateCoupon(post)
        } else {
            return handleCreateCoupon(post)
        }
    }

    async function handleDeleteCoupon(post) {
        try {
            if (!post?.id) {
                return
            }

            await api.deleteResource("/coupons", token, post.id)
            fetchCoupons()

            message.success("Articolo eliminato con successo")
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleCouponBottomSheetCancel() {
        setSelectedCoupon(null)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => setSelectedCoupon(row)} />

                <StyledPopconfirm
                    onConfirm={() => handleDeleteCoupon(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo articolo?"
                >
                    <DeleteButton />
                </StyledPopconfirm>
            </div>
        )
    }

    const changeHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setImportedCoupons(results.data)
            },
        })
    }

    async function createCoupons() {
        importedCoupons?.map(async (coupon) => {
            coupons?.find((x) => x?.code === coupon?.COD)
                ? await handleUpdateCoupon(
                      getCouponBody(coupon),
                      coupons?.find((x) => x?.code === coupon?.COD)?.id
                  )
                : await handleCreateCoupon(getCouponBody(coupon))
        })
    }

    function getCouponBody(coupon) {
        const body = {
            code: coupon?.COD,
            name: coupon?.Nome,
            descrizione: coupon?.Descrizione,
            currency: coupon?.Percentuale === "NO" ? 1 : 2,
            EAN: coupon?.EAN,
            validDate: [
                moment(coupon?.["Inizio Validita"], "DD/MM/YYYY"),
                moment(coupon?.["Fine Validita"], "DD/MM/YYYY"),
            ],

            value: coupon?.Valore,
            addresses: coupon?.["Mail Associata"],
            shopCoupon: coupon?.["Utilizzo in negozio"] === "SI",
            image: coupon?.Immagine,
            minPoints: coupon?.["Min Punti"],
        }

        return body
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const defaultCoupon = {
        published: false,
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Coupons</h1>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <AppButton
                    uxType="secondary"
                    onClick={() => setSelectedCoupon(defaultCoupon)}
                >
                    Nuovo Coupon
                </AppButton>
                <div style={{ marginLeft: 10 }}>
                    <AppButton
                        uxType="secondary"
                        onClick={() => setOpenSelectFile(true)}
                    >
                        Importa file
                    </AppButton>
                </div>
            </div>

            <Table
                dataSource={coupons}
                pagination={{ ...pagination, total: totalRecordsNumber }}
                onChange={(pagination) => fetchCoupons({ pagination })}
                columns={getColumns(renderActions)}
                loading={loading}
            />

            <CouponsFormBottomSheet
                open={selectedCoupon ? true : false}
                post={selectedCoupon}
                onCancel={handleCouponBottomSheetCancel}
                onConfirm={handleCouponBottomSheetConfirm}
            />

            <Modal
                cancelButtonProps={{ style: { display: "none" } }}
                open={openSelectFile}
                onOk={() => createCoupons()}
                onCancel={() => setOpenSelectFile(false)}
            >
                <input
                    onChange={changeHandler}
                    type="file"
                    name="file"
                    accept=".csv"
                    style={{ display: "block", margin: "10px auto" }}
                />
            </Modal>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Coupons.propTypes = {
    className: PropTypes.string.isRequired,
}

Coupons.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledCoupons = styled(Coupons)`
    & {
        .actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledCoupons

function getColumns(renderActions) {
    return [
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
        },

        {
            key: "actions",
            render: renderActions,
        },
    ]
}
