import React, { useState } from "react"
import { message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import * as api from "../../../../api/Local"

import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import useRestResource from "../../../_shared/hooks/useRestResource"

import Table from "../../../_shared/components/Table"

import { Link, Route, Switch, useLocation } from "react-router-dom"
import Banners from "./Banners"
import StoriesVideo from "./StoriesVideo"
import StandbyVideo from "./StandbyVideo"
import HighlightedBrands from "./HighlightedBrands"

import Home from "../CMS/Home"
import Shop from "../CMS/Shop"
import TopMessage from "../CMS/TopMessage"
import HiddenBrands from "./HiddenBrands"
import AppButton from "../../../_shared/components/AppButton"

// ----------------------------------------------------------------------------

function _Totem(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------
    const { id } = useLocation()

    const isTablet = props?.location?.pathname.includes("tablet")
    const slug = props?.location?.pathname.includes("totem") ? getSlug() : ""

    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")
        return splitArray[splitArray?.length - 1]
    }

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    function renderRoutesTOTEM() {
        return (
            <div className="routes">
                <Link
                    className="route"
                    to={{
                        pathname: `/totem/${slug}/banners`,
                    }}
                >
                    Banners
                </Link>
                <Link
                    className="route"
                    to={{ pathname: `/totem/${slug}/highbrands` }}
                >
                    Marchi in evidenza
                </Link>
                <Link
                    className="route"
                    to={{ pathname: `/totem/${slug}/hiddenbrands` }}
                >
                    Marchi nascosti
                </Link>
                {/* <Link className="route" to="/totem/stories">
                    Video stories
                </Link>
                <Link className="route" to="/totem/standbyVideo">
                    Standby video
                </Link> */}
            </div>
        )
    }

    async function cancelPosOperation() {
        const call = await api.cancelPosCall()
        message.success("Operazione annullata con successo")
    }

    function renderRoutesAPP() {
        return (
            <div className="routes">
                <Link className="route" to={`/totem/${slug}/home`}>
                    Widget Pagina Home
                </Link>
                <Link
                    className="route"
                    to={{
                        pathname: `/totem/${slug}/shop`,
                    }}
                >
                    Widget Pagina Shop
                </Link>
                <Link
                    className="route"
                    to={{
                        pathname: `/totem/${slug}/top-message`,
                    }}
                >
                    Top Message
                </Link>
                <Link
                    className="route"
                    to={{
                        pathname: `/totem/${slug}/hiddenbrands`,
                    }}
                >
                    Marchi nascosti
                </Link>
                <div style={{ marginTop: 50 }}>
                    <AppButton
                        onClick={() => cancelPosOperation()}
                        uxType={"delete"}
                    >
                        ANNULLA OPERAZIONE POS
                    </AppButton>
                </div>
            </div>
        )
    }

    // function getActiveRouteName() {
    //     switch (pathname) {
    //         case "/totem":
    //             return ""
    //         case "/totem/banners":
    //             return "- Banners"
    //         case "/totem/highbrands":
    //             return "- Highlighted brands"
    //         case "/totem/stories":
    //             return "- Stories"
    //         case "/totem/standbyVideo":
    //             return "- Standby video"
    //     }
    // }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Totem </h1>
            <Switch>
                <Route
                    path="/totem/:slug"
                    exact={true}
                    component={!isTablet ? renderRoutesTOTEM : renderRoutesAPP}
                />
                <Route path={"/totem/:slug/banners"} component={Banners} />
                <Route
                    path="/totem/:slug/highbrands"
                    component={HighlightedBrands}
                />
                <Route path="/totem/:slug/stories" component={StoriesVideo} />
                <Route
                    path="/totem/:slug/standbyVideo"
                    component={StandbyVideo}
                />
                <Route
                    path={"/totem/:slug/hiddenbrands"}
                    component={HiddenBrands}
                />
                <Route path="/totem/:slug/home" component={Home} />
                <Route path="/totem/:slug/shop" component={Shop} />
                <Route path="/totem/:slug/top-message" component={TopMessage} />
            </Switch>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Totem.propTypes = {
    className: PropTypes.string.isRequired,
}

_Totem.defaultProps = {}

// ----------------------------------------------------------------------------

const Totem = styled(_Totem)`
    & {
        .routes {
            width: 100%;
            .route {
                height: 50px;
                width: 100%;
                padding-left: 10px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
                color: ${({ theme }) => theme.colors.textPrimary};
                transition: all 200ms ease;
                font-size: 16px;

                &:hover {
                    background-color: ${({ theme }) => theme.colors.grey200};
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default Totem

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "mode",
            title: "Tipo",
            dataIndex: "mode",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
            render: (published) =>
                published && <Tag color="green">PUBBLICATA</Tag>,
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
