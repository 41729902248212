import React, { useEffect, useMemo, useState } from "react"
import { Popconfirm, Table, message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestTable from "../../../../_shared/hooks/usePaginatedRestResource"
import BannerBottomSheet from "./HighBrandsBottomSheet"
import AppButton from "../../../../_shared/components/AppButton"
import * as api from "../../../../../api/Local"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import EditButton from "../../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../../_shared/components/Buttons/DeleteButton"

// ----------------------------------------------------------------------------

function _HighlightedBrands(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const [{ token }] = useStateValue()

    const slug = props?.location?.pathname.includes("totem") ? getSlug() : ""

    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")
        return splitArray[splitArray?.length - 2]
    }

    const [
        highlightedbrands,
        fetchHighlightedBrands,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource(`totemstore/${slug}/highlightedbrands`, token)

    useEffect(() => {
        fetchHighlightedBrands()
    }, [])
    const [selectedItem, setSelectedItem] = useState()

    const { className } = props

    async function handleConfirm(formValues) {
        try {
            if (formValues.image) {
                if (typeof formValues.image === "object") {
                    const uploadResponse = await api.uploadFile(
                        formValues.image
                    )

                    formValues.image = uploadResponse?.url ?? formValues.image
                }
            }

            const newHighBrand = formValues?.id
                ? await api.updateHighlightedBrands(
                      formValues?.id,
                      formValues,
                      token
                  )
                : await api.createHighlightedBrands(formValues, token)

            const storeView = await api.getTotemStore(token, slug)

            const newStoreView = {
                ...storeView,
                highlightedBrands: [
                    ...(storeView?.highlightedBrands ?? []),
                    newHighBrand?.id,
                ],
            }
            console.log("newStoreView : ", newStoreView)

            await api.updateTotemStore(slug, newStoreView, token)
            fetchHighlightedBrands()
            message.success("Operazione completata con successo")
            setSelectedItem(null)
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }

    function handleCancel() {
        setSelectedItem(null)
    }

    async function handleDeleteBrand(brand) {
        try {
            if (!brand?.id) {
                return
            }

            await api.deleteHighlightedBrand(brand.id, token)
            fetchHighlightedBrands()

            message.success("Brand eliminato con successo")
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => setSelectedItem(row)} />

                <Popconfirm
                    onConfirm={() => handleDeleteBrand(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo brand?"
                >
                    <DeleteButton />
                </Popconfirm>
            </div>
        )
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">HighlightedBrands</h1>
            <AppButton uxType="secondary" onClick={() => setSelectedItem({})}>
                Nuovo brand in evidenza
            </AppButton>
            <BannerBottomSheet
                defaultValues={selectedItem}
                open={!!selectedItem}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            ></BannerBottomSheet>

            <Table
                dataSource={highlightedbrands}
                columns={getColumns(renderActions)}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HighlightedBrands.propTypes = {
    className: PropTypes.string.isRequired,
}

_HighlightedBrands.defaultProps = {}

// ----------------------------------------------------------------------------

const HighlightedBrands = styled(_HighlightedBrands)`
    & {
        .stats-container {
            margin: 20px 0;
            padding: 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .actions {
            flex-direction: "row";
            display: flex;
        }
        .available-for-list {
            span {
                display: block;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default HighlightedBrands

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
