import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Tag } from "antd"

import * as api from "../../../../api/Local"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"
import Table from "../../../_shared/components/Table"
import Button from "../../../_shared/components/AppButton"
import AppSwitch from "../../../_shared/components/AppSwitch"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import Popconfirm from "../../../_shared/components/Popconfirm"

import DiscountBottomSheet from "./DiscountBottomSheet"

// ----------------------------------------------------------------------------

function Discount(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [discounts, fetchResource] = usePaginatedRestResource(
        "/discounts",
        token
    )

    const [selectedDiscount, setSelectedDiscount] = useState(null)

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleDeleteDiscount(discount) {
        try {
            await api.deleteResource(`/discounts`, token, discount.id)

            fetchResource()

            message.success("Sconto eliminato con successo")
        } catch (error) {
            console.error(error)

            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleCreateDiscount(discount) {
        try {
            await api.createResource("/discounts", token, discount)
            fetchResource()
            message.success("Sconto creato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdateDiscount(discount) {
        try {
            await api.updateResource(
                `/discounts`,
                token,
                selectedDiscount.id,
                discount
            )
            fetchResource()
            message.success("Sconto aggiornato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleDiscountBottomSheetConfirm(discount) {
        setSelectedDiscount(null)
        if (discount.id) {
            return handleUpdateDiscount(discount)
        } else {
            return handleCreateDiscount(discount)
        }
    }

    function handleEditDiscount(discount) {
        setSelectedDiscount(discount)
    }

    function handleDiscountBottomSheetCancel() {
        setSelectedDiscount(null)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => handleEditDiscount(row)} />
                <Popconfirm
                    onConfirm={() => handleDeleteDiscount(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo sconto?"
                >
                    <DeleteButton />
                </Popconfirm>
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const defaultDiscount = {
        enabled: true,
        type: "percentage",
    }

    return (
        <div className={props.className}>
            <h1 className="page-title">Sconti</h1>

            <Button onClick={() => setSelectedDiscount(defaultDiscount)}>
                Nuovo sconto
            </Button>
            <Table
                className="table"
                dataSource={discounts}
                columns={getColumns(renderActions)}
                rowKey={(row) => row.id}
            />

            <DiscountBottomSheet
                open={selectedDiscount ? true : false}
                discount={selectedDiscount}
                onCancel={handleDiscountBottomSheetCancel}
                onConfirm={handleDiscountBottomSheetConfirm}
            />
            {/* <AppSwitch value={isDiscountEnabled} /> */}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Discount.propTypes = {
    className: PropTypes.string.isRequired,
}

Discount.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledDiscount = styled(Discount)`
    & {
        .table {
            a {
                &:hover {
                    color: ${({ theme }) => theme.colors.primary};
                }
            }
            .handled {
                color: ${({ theme }) => theme.colors.success};
            }
            .unhandled {
                color: ${({ theme }) => theme.colors.error};
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledDiscount

// ----------------------------------------------------------------------------

const TYPE_MAP = {
    percentage: {
        label: "Percentuale",
        color: "blue",
    },
    amount: {
        label: "Importo",
        color: "magenta",
    },
}

function getColumns(renderActions) {
    return [
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
            // eslint-disable-next-line
            render: (t) => <strong>{t}</strong>,
        },
        {
            title: "Valore",
            key: "value",
            dataIndex: "value",
            // eslint-disable-next-line
            render: (t) => <strong>{t}</strong>,
        },
        {
            title: "Tipo",
            key: "type",
            dataIndex: "type",
            // eslint-disable-next-line
            render: (type) => (
                <Tag color={TYPE_MAP[type]?.color}>{TYPE_MAP[type].label}</Tag>
            ),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
