import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { updateCmsSection } from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import Switch from "../../../_shared/components/AppSwitch"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import useForm from "../../../_shared/hooks/useForm"
import useRestResource from "../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _FreeShippingThreshold(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [form, setOne, setMany] = useForm()
    const [{ token }] = useStateValue()
    const cmsResourceIdentifier = "free-shipping"
    const [cmsContent, fetchCmsContent] = useRestResource(
        "/cms",
        cmsResourceIdentifier,
        token
    )

    const [submitting, setSubmitting] = useState(false)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        cmsContent &&
            setMany({
                ...cmsContent.content,
            })
    }, [cmsContent])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function updateForm(field) {
        return (value) => {
            setOne(field, value)
        }
    }

    function handleReset() {
        cmsContent &&
            setMany({
                ...cmsContent.content,
            })
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        try {
            await updateCmsSection(
                cmsResourceIdentifier,
                { content: form },
                token
            )
            message.success("Elementi aggiornati con successo")
            fetchCmsContent && fetchCmsContent()
        } catch (e) {
            console.log(e)
            message.error("Errore durante l'aggiornamento degli elementi")
        }
        setSubmitting(false)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <form onSubmit={handleSubmit} className={`${className}`}>
            <h1 className="page-title">Soglia spedizione gratuita</h1>
            <Switch
                label="Abilita spedizioni gratuite"
                className="field"
                value={form?.enabled}
                onChange={updateForm("enabled")}
            />
            <AppInput
                className="field"
                label="Soglia spedizioni gratuite"
                value={form?.threshold}
                onChange={updateForm("threshold")}
            ></AppInput>
            <AppInput
                className="field"
                label="Costo di spedizione standard"
                value={form?.defaultShippingPrice}
                onChange={updateForm("defaultShippingPrice")}
            ></AppInput>
            <div className="buttons-row">
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="button"
                    onClick={handleReset}
                >
                    Annulla modifiche
                </AppButton>
                <AppButton
                    uxType="primary"
                    htmlType="submit"
                    loading={submitting}
                >
                    Salva
                </AppButton>
            </div>
        </form>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_FreeShippingThreshold.propTypes = {
    className: PropTypes.string.isRequired,
}

_FreeShippingThreshold.defaultProps = {}

// ----------------------------------------------------------------------------

const FreeShippingThreshold = styled(_FreeShippingThreshold)`
    & {
        .field {
            width: 400px;
            max-width: unset;
            margin-bottom: 20px;
        }
        .buttons-row {
            margin-top: 30px;
            button {
                height: 48px;
                margin-right: 10px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default FreeShippingThreshold
