import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaImage } from "react-icons/fa"
import styled from "styled-components"

//------------------------------------------------------------------------------

function ImageUploadInput(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { image, onUpload, noPreview } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [preview, setPreview] = useState(null)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        setPreview(image)
    }, [image])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleUploadChange(event) {
        event.preventDefault()

        const reader = new FileReader()
        const file = event.target.files[0]

        if (file) {
            reader.onloadend = () => {
                setPreview(reader.result)

                onUpload && onUpload(file)
            }

            reader.readAsDataURL(file)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const imgStyle = {
        backgroundImage: `url(${!noPreview && preview})`,
    }

    console.log("VIDEO: ", image)

    return (
        <div className={props.className} style={{ ...imgStyle }}>
            <input
                type="file"
                accept="video/*"
                onChange={handleUploadChange}
                onSubmit={(e) => e.preventDefault()}
            />

            {(!preview || noPreview) && <FaImage className="placeholder-icn" />}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ImageUploadInput.propTypes = {
    className: PropTypes.string.isRequired,
    image: PropTypes.any,
    onUpload: PropTypes.func,
    noPreview: PropTypes.bool,
}

ImageUploadInput.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledImageUploadInput = styled(ImageUploadInput)`
    & {
        position: relative;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 500px;
        height: 200px;

        border-radius: 4px;
        background-color: ${(props) => props.theme.colors.grey200};

        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        cursor: pointer;

        transition: all 0.2s ease-in-out;

        :hover {
            background-color: ${(props) => props?.theme?.colors?.grey350};
        }

        > input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;

            cursor: pointer;
        }

        .placeholder-icn {
            font-size: 50px;
            color: ${(props) => props?.theme?.colors?.dropzoneTextColor};
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledImageUploadInput
