import { Popconfirm, message } from "antd"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

// ----------------------------------------------------------------------------

function _AccountDeleteRequests(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()
    const [filter, setFilter] = useState("")
    const [requests, fetchRequests, loading, pagination, , onFiltersChange] =
        usePaginatedRestResource("/account-delete-requests", token)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------
    useEffect(() => {
        if (filter.length >= 3) {
            onFiltersChange({ email: filter })
        } else {
            onFiltersChange({
                email: "",
            })
        }
    }, [filter])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function restoreAccount(row) {
        const { email } = row
        try {
            await api.whitelistAccount(email, token)
            fetchRequests()
            message.success("Utente riabilitato")
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
            message.error("Qualcosa è andato storto")
        }
    }

    function renderActions(row) {
        return (
            <Popconfirm
                title={"Riabilitare questo utente?"}
                onConfirm={() => restoreAccount(row)}
            >
                <AppButton uxType="secondary">Riabilita</AppButton>
            </Popconfirm>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Account eliminati</h1>
            <AppInput
                value={filter}
                onChange={setFilter}
                className="search"
                label="Cerca indirizzo"
            ></AppInput>
            <Table
                dataSource={requests}
                pagination={pagination}
                onChange={(pagination) => fetchRequests({ pagination })}
                columns={getColumns({ renderActions })}
                loading={loading}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_AccountDeleteRequests.propTypes = {
    className: PropTypes.string.isRequired,
}

_AccountDeleteRequests.defaultProps = {}

// ----------------------------------------------------------------------------

const AccountDeleteRequests = styled(_AccountDeleteRequests)`
    & {
        .search {
            margin-bottom: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default AccountDeleteRequests

function getColumns({ renderActions }) {
    return [
        {
            title: "Email",
            key: "email",
            dataIndex: "email",
        },
        {
            title: "Data",
            key: "date",
            dataIndex: "createdAt",
            render: (v) => dayjs(v).format("D MMM YYYY HH:mm"),
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
