import PropTypes from "prop-types"
import React, { useEffect } from "react"
import styled from "styled-components"

import Switch from "../../../../_shared/components/AppSwitch"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"
import ImageUploadInput from "../../../../_shared/components/FormItems/ImageUploadInput"

import AppDatePicker from "../../../../_shared/components/FormItems/AppDatePicker"
import WYSIWYG from "../../../../_shared/components/FormItems/WYSIWYG"
import useForm from "../../../../_shared/hooks/useForm"

// ----------------------------------------------------------------------------

function StoreFormBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, post, onConfirm, ...sheetProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialValues = post ?? {}

    const [formValues, setFormValue, setFormValues, clearForm] =
        useForm(initialValues)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (open) {
            setFormValues(post ?? {})
        } else {
            clearForm()
        }
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            console.log(">>> handleFormValueChange: ", key, value)
            setFormValue(key, value)
        }
    }

    function handleConfirm() {
        onConfirm?.(formValues)
    }

    function getOptions() {
        return [
            {
                value: "percentage",
                label: "Percentuale",
            },
            {
                value: "amount",
                label: "Importo",
            },
        ]
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    console.log("STORE: ", formValues)

    return (
        <BottomSheet
            className={props.className}
            open={open}
            snapPoint={80}
            title={post?.id ? "Modifica store" : "Nuovo store"}
            onConfirm={handleConfirm}
            {...sheetProps}
        >
            <Input
                className="form-control"
                label="Nome"
                value={formValues.name}
                onChange={handleFormValueChange("name")}
            />
            <Input
                className="form-control"
                label="Indirizzo"
                value={formValues.address}
                onChange={handleFormValueChange("address")}
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

StoreFormBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    post: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
}

StoreFormBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const StoreFormBottomSheetWithStyle = styled(StoreFormBottomSheet)`
    & {
        .sheet-content {
            max-width: 500px;

            .form-control {
                margin-bottom: 20px;
                width: 500px;
                max-width: 500px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StoreFormBottomSheetWithStyle
