import { message } from "antd"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import {
    createCMS,
    getTotemStore,
    updateCmsSection,
    updateTotemStore,
} from "../../../../../api/Local"
import AppButton from "../../../../_shared/components/AppButton"
import ColorPicker from "../../../../_shared/components/ColorPickerPopoverTag"
import Input from "../../../../_shared/components/FormItems/AppInput"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"

// ----------------------------------------------------------------------------

function _TopMessage(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const isTotem = props?.location?.pathname.includes("totem")
    const slug = props?.location?.pathname.includes("totem") ? getSlug() : ""

    const [{ token }] = useStateValue()
    const cmsResourceIdentifier = "top-message"
    const [topMessageContent, fetchTopMessageContent] = useRestResource(
        isTotem ? `/cms/${slug}-top-message` : "/cms",
        isTotem ? "" : cmsResourceIdentifier,
        token
    )

    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")
        return splitArray[splitArray?.length - 2]
    }

    const [submitting, setSubmitting] = useState(false)
    const [form, setOne, setMany] = useForm()

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        topMessageContent &&
            setMany({
                ...topMessageContent.content,
            })
    }, [topMessageContent])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function updateForm(field) {
        return (value) => {
            setOne(field, value)
        }
    }

    function handleReset() {
        topMessageContent &&
            setMany({
                ...topMessageContent.content,
            })
    }

    async function updateTotemCms(body) {
        if (!topMessageContent?.id) {
            const newBody = {
                ...body,
                content: form,
                slug: slug + "-top-message",
            }
            const cms = await createCMS(newBody, token)
            const storeView = await getTotemStore(token, slug)
            const newStoreView = {
                ...storeView,
                cms: [...(storeView?.cms ?? []), cms?.id],
            }
            console.log("new store view : ", newStoreView)
            await updateTotemStore(slug, newStoreView, token)
        }

        await updateCmsSection(slug + "-top-message", { content: body }, token)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        try {
            isTotem
                ? await updateTotemCms(form)
                : await updateCmsSection(
                      cmsResourceIdentifier,
                      { content: form },
                      token
                  )
            message.success("Elementi aggiornati con successo")
            fetchTopMessageContent && fetchTopMessageContent()
        } catch (e) {
            console.log(e)
            message.error("Errore durante l'aggiornamento degli elementi")
        }
        setSubmitting(false)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <form onSubmit={handleSubmit} className={`${className}`}>
            <Input
                value={form?.title}
                onChange={updateForm("title")}
                label="Titolo"
                className="field"
            />
            <Input
                value={form?.subtitle}
                onChange={updateForm("subtitle")}
                label="Sottotitolo"
                className="field"
            />
            <span className="field-label">Colore testo</span>
            <ColorPicker
                color={form?.textColor}
                onSave={(color) => updateForm("textColor")(color.hex)}
                className="color-picker"
            />
            <span className="field-label">Colore sfondo</span>
            <ColorPicker
                color={form?.backgroundColor}
                onSave={(color) => updateForm("backgroundColor")(color.hex)}
                className="color-picker"
            />
            <div className="buttons-row">
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="button"
                    onClick={handleReset}
                >
                    Annulla modifiche
                </AppButton>
                <AppButton
                    uxType="primary"
                    htmlType="submit"
                    loading={submitting}
                >
                    Salva
                </AppButton>
            </div>
        </form>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_TopMessage.propTypes = {
    className: PropTypes.string.isRequired,
}

_TopMessage.defaultProps = {}

// ----------------------------------------------------------------------------

const TopMessage = styled(_TopMessage)`
    & {
        .field {
            width: 400px;
            margin-bottom: 20px;
            max-width: unset;
        }
        .field-label {
            margin-bottom: 10px;
            display: block;
        }
        .color-picker {
            width: 250px;
            margin-bottom: 20px;
            .tag {
                border: 1px solid black;
            }
        }
        .buttons-row {
            margin-top: 30px;
            button {
                height: 48px;
                margin-right: 10px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default TopMessage
