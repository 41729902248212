import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import { useStateValue } from "../../../../../_shared/context/AppStateStore"
import useRestTable from "../../../../../_shared/hooks/usePaginatedRestResource"
import AppSelect from "../../../../../_shared/components/FormItems/AppSelect"
import moment from "moment"
import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import BottomSheet from "../../../../../_shared/components/BottomSheet"
import useForm from "../../../../../_shared/hooks/useForm"
import WYSIWYG from "../../../../../_shared/components/FormItems/WYSIWYG"

import StyledImageUploadInput from "../../../../../_shared/components/FormItems/ImageUploadInput"
import StyledVideoUploadInput from "../../../../../_shared/components/FormItems/VideoUploadInput"
// ----------------------------------------------------------------------------

function _HighlightedBrandsBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className, onCancel, onConfirm, defaultValues, open } = props

    const [{ token }] = useStateValue()
    const [filters] = useRestTable("/storeden/filters", token)
    const [storedenCategories] = useRestTable("/storeden/categories", token)

    const [brands] = useRestTable("/storeden/brands", token)
    const [categories] = useRestTable("/storeden/categories", token)

    const initialValues = defaultValues ?? {}

    const [formValues, setFormValue] = useState(defaultValues)

    useEffect(() => {
        setFormValue(defaultValues)
    }, [defaultValues])

    const brandOptions = useMemo(() => {
        return brands?.map((brand) => ({ label: brand.name, value: brand.uid }))
    }, [brands])

    function handleConfirm() {
        console.log("form values save : ", formValues)
        onConfirm && onConfirm(formValues)
    }

    function handleFormValueChange(key, value) {
        setFormValue({ ...formValues, [key]: value })
    }

    function getTargetFilterOption() {
        return (input, option) => {
            const pat = new RegExp(input?.toLowerCase())
            return pat.test(option?.children?.toLowerCase())
        }
    }

    return (
        <BottomSheet
            snapPoint={80}
            open={open}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            className={`${className}`}
            title={
                formValues?.id
                    ? "Modifica brand in evidenza"
                    : "Nuovo brand in evidenza"
            }
        >
            <AppInput
                className="form-control"
                label="Titolo"
                value={formValues?.title}
                onChange={(e) => handleFormValueChange("title", e)}
            />

            <StyledImageUploadInput
                label="Immagine"
                image={formValues?.image}
                onUpload={(e) => handleFormValueChange("image", e)}
            />
            <AppSelect
                value={formValues?.target}
                onChange={(e) => handleFormValueChange("target", e)}
                options={brandOptions}
                filterOption={getTargetFilterOption()}
                className="input"
                placeholder="Seleziona categoria"
                label="Brand"
            />
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_HighlightedBrandsBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    defaultValues: PropTypes.object.isRequired,
}

_HighlightedBrandsBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const HighlightedBrandsBottomSheet = styled(_HighlightedBrandsBottomSheet)`
    & {
        .sheet-content {
            max-width: 500px;

            .form-control {
                margin-bottom: 20px;
                width: 500px;
                max-width: 500px;
            }
            .input {
                margin-top: 20px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default HighlightedBrandsBottomSheet

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
            render: (published) =>
                published && <Tag color="green">PUBBLICATA</Tag>,
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
