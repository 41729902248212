import { message } from "antd"
import { Modal } from "antd"
import PropTypes, { element } from "prop-types"
import { uid } from "rand-token"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import Collapsible from "react-collapsible"
import { IoIosArrowDown, IoIosInformationCircleOutline } from "react-icons/io"
import styled from "styled-components"

import {
    createCMS,
    deleteStoredenFile,
    getTotemStore,
    updateCmsSection,
    updateTotemStore,
    uploadFile,
} from "../../../../../api/Local"
import AppButton from "../../../../_shared/components/AppButton"
import AppInput from "../../../../_shared/components/FormItems/AppInput"

import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useForm from "../../../../_shared/hooks/useForm"
import useRestResource from "../../../../_shared/hooks/useRestResource"
import HeroEditor from "../Home/HeroEditor"

// ----------------------------------------------------------------------------

function _Shop(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------
    const [{ token }] = useStateValue()
    const isTotem = props?.location?.pathname.includes("totem")
    const slug = props?.location?.pathname.includes("totem") ? getSlug() : ""

    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")

        return splitArray[splitArray?.length - 2]
    }

    const [shopContent, fetchshopContent] = useRestResource(
        isTotem ? `/cms/${slug}-shop` : "/cms",
        isTotem ? "" : "home",
        token
    )
    const [form, setOne, setMany] = useForm()
    const [openWidget, setOpenwidget] = useState(null)
    const [openItem, setOpenitem] = useState(1)

    const [submitting, setSubmitting] = useState(false)
    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        shopContent &&
            setMany({
                ...shopContent,
            })
    }, [shopContent])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    const updateForm = (widgetKey) => (field) => (value) => {
        const updatedWidgets = form?.widgets?.map((w) => {
            if (w.id === widgetKey) {
                return {
                    ...w,
                    [field]: value,
                }
            } else return w
        })

        setOne("widgets", updatedWidgets)
    }

    function getWidget(key) {
        return form?.widgets?.find((w) => w.id === key)
    }

    function handleAddWidget() {
        const newWidgets = [getNewWidget(), ...(form?.widgets ?? [])]
        setOne("widgets", newWidgets)
    }

    function handleAddSliderWidget() {
        const newWidgets = [getNewSliderWidget(), ...(form?.widgets ?? [])]

        setOne("widgets", newWidgets)
    }

    function handleNewItem(widget) {
        const newItems = [
            ...(widget.content ?? []),
            getNewItem(widget.content.length),
        ]

        const updatedW = form?.widgets?.map((w) => {
            if (w.id === widget.id) {
                return {
                    ...w,
                    content: newItems,
                }
            }

            return {
                ...w,
            }
        })

        setOne("widgets", updatedW)
    }

    function handleReset() {
        function execute() {
            setOpenwidget(null)
            setMany(shopContent)
        }
        Modal.confirm({
            onOk: execute,
            title: "Conferma azione",
            content:
                "Stai per resettare le modifiche effettuate ai loro valori precedentemente salvati.",
        })
    }

    async function handleImageUpload(values) {
        if (values?.widgets) {
            for (let widget of values?.widgets) {
                if (widget.subType === "sliderWidget") {
                    for (let item of widget?.content) {
                        if (item.image) {
                            if (!(typeof item.image === "string")) {
                                const oldWidget = shopContent?.widgets
                                    .find((x) => x.id === widget.id)
                                    .content?.find(
                                        (w) => String(w.id) === String(item.id)
                                    )
                                if (oldWidget && oldWidget.imageId) {
                                    const imageId = oldWidget.imageId
                                    await deleteStoredenFile(
                                        imageId,
                                        token
                                    ).catch((e) => console.log(e))
                                }
                                const newImage = await uploadFile(
                                    item.image,
                                    token
                                )
                                item.image = newImage?.url
                                item.imageId = newImage?.id
                                item.thumbnail = newImage?.thumbnail
                            }
                        }
                    }
                } else {
                    if (widget.image) {
                        if (!(typeof widget.image === "string")) {
                            const oldWidget = shopContent?.widgets?.find(
                                (w) => String(w.id) === String(widget.id)
                            )
                            if (oldWidget && oldWidget.imageId) {
                                const imageId = oldWidget.imageId
                                await deleteStoredenFile(imageId, token).catch(
                                    (e) => console.log(e)
                                )
                            }
                            const newImage = await uploadFile(
                                widget.image,
                                token
                            )
                            widget.image = newImage?.url
                            widget.imageId = newImage?.id
                            widget.thumbnail = newImage?.thumbnail
                        }
                    }
                }
            }
            return values
        }
    }

    async function updateTotemCms(body) {
        if (!shopContent?.id) {
            const newBody = { ...body, slug: slug + "-shop" }
            const cms = await createCMS(newBody, token)
            const storeView = await getTotemStore(token, slug)
            const newStoreView = {
                ...storeView,
                cms: [...(storeView?.cms ?? []), cms?.id],
            }
            console.log("new store view : ", newStoreView)
            await updateTotemStore(slug, newStoreView, token)
        }

        await updateCmsSection(slug + "-shop", body, token)
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setSubmitting(true)
        try {
            const body = await handleImageUpload(form)

            isTotem
                ? await updateTotemCms(body)
                : await updateCmsSection("shop", body, token)
            message.success("Elementi aggiornati con successo")
            fetchshopContent && fetchshopContent()
        } catch (e) {
            console.log(e)
            message.error("Errore durante l'aggiornamento degli elementi")
        }
        setSubmitting(false)
    }

    function handleWidgetToggled(widget) {
        const isOpen = widget.id === openWidget
        setOpenwidget(isOpen ? null : widget.id)
    }
    function handleItemToggled(item) {
        const isOpen = item.id === openItem
        setOpenitem(isOpen ? null : item.id)
    }

    function handleWidgetDelete(widget) {
        const newWidgets = form?.widgets?.filter((w) => w.id !== widget.id)
        setOne("widgets", newWidgets)
    }

    function handleDeleteItem(deleteId) {
        const newWidgets = form?.widgets[0]?.content.filter(
            (w) => w.id !== deleteId.id
        )

        const updatedW = form?.widgets?.map((w) => {
            return {
                ...w,
                content: newWidgets,
            }
        })

        setOne("widgets", updatedW)
    }

    function renderDynamicWidget(widget, index) {
        return (
            <Draggable
                draggableId={String(widget.id)}
                index={index}
                key={widget.id}
                isDragDisabled={openWidget ? true : false}
            >
                {(provided) => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={`widget widget-dynamic ${
                            widget.id === openWidget && "widget-open"
                        }`}
                    >
                        <div
                            onClick={() => handleWidgetToggled(widget)}
                            className={`widget-trigger `}
                        >
                            <span className="widget-trigger-title">
                                {widget?.title}
                            </span>
                            <IoIosArrowDown className="widget-trigger-icon" />
                        </div>
                        <div className="widget-content">
                            <Collapsible open={widget.id === openWidget}>
                                <HeroEditor
                                    className="hero-editor"
                                    values={getWidget(widget.id)}
                                    onChange={updateForm(widget.id)}
                                    onDelete={handleWidgetDelete}
                                />
                            </Collapsible>
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }

    const updateFormItem = (contentKey, widget) => (field) => (value) => {
        const updatedContent = widget.content?.map((w) => {
            if (w.id === contentKey) {
                return {
                    ...w,
                    [field]: value,
                }
            } else return w
        })

        const updatedW = form?.widgets?.map((w) => {
            if (w.id === widget.id) {
                return {
                    ...w,
                    content: updatedContent,
                }
            }

            return {
                ...w,
            }
        })

        setOne("widgets", updatedW)
    }

    function handleDragEnd(result) {
        const id = result?.draggableId
        const destinationIndex = result?.destination?.index
        const sourceIndex = result?.source?.index
        if (
            id &&
            typeof destinationIndex !== "undefined" &&
            typeof sourceIndex !== "undefined"
        ) {
            const widget = form?.widgets?.find((w) => w.id === id)
            const newWidgets = [...(form?.widgets ?? [])]
            //remove old index
            newWidgets.splice(sourceIndex, 1)
            // insert new item at destination index
            newWidgets.splice(destinationIndex, 0, widget)

            setOne("widgets", newWidgets)
        }
    }

    function handleDragEndItem(result) {
        const id = result?.draggableId
        const destinationIndex = result?.destination?.index
        const sourceIndex = result?.source?.index
        if (
            id &&
            typeof destinationIndex !== "undefined" &&
            typeof sourceIndex !== "undefined"
        ) {
            const item = form?.widgets
                ?.filter((x) => x.subType === "sliderWidget")
                .map((x) => x.content.find((w) => w.id === id))?.[0]
            if (!item) return

            const widgets = form?.widgets?.filter(
                (x) => x.subType === "sliderWidget"
            )

            let widget
            widgets.forEach(function (element) {
                if (element.content.filter((x) => x.id === id).length > 0) {
                    widget = element
                }
            })

            const newWidgetContent = widget?.content
            //remove old index
            newWidgetContent.splice(sourceIndex, 1)
            // insert new item at destination index
            newWidgetContent.splice(destinationIndex, 0, item)

            const updatedW = form?.widgets?.map((w) => {
                if (w.id === widget.id)
                    return {
                        ...w,
                        content: newWidgetContent,
                    }
                return {
                    ...w,
                }
            })
            setOne("widgets", updatedW)
        }
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <form onSubmit={handleSubmit} className={`${className}`}>
            <div className="drag-help-wrapper">
                <IoIosInformationCircleOutline />

                <span className="drag-help">
                    Trascina le sezioni per modificare l&apos;ordine degli
                    elementi. Le sezioni in grigio non si possono modificare o
                    eliminare.
                </span>
            </div>
            <AppButton
                uxType="secondary"
                htmlType="button"
                className="add-button"
                onClick={handleAddWidget}
            >
                Aggiungi widget
            </AppButton>
            <AppButton
                uxType="secondary"
                htmlType="button"
                className="add-button"
                onClick={handleAddSliderWidget}
            >
                Aggiungi slider di immagini
            </AppButton>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="widgets-drop-area">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className="widgets"
                        >
                            {form?.widgets?.map((widget, index) =>
                                renderDynamicWidget(widget, index)
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="buttons-row">
                <AppButton
                    uxType="delete"
                    htmlType="button"
                    className="button"
                    onClick={handleReset}
                >
                    Annulla modifiche
                </AppButton>
                <AppButton
                    uxType="primary"
                    htmlType="submit"
                    loading={submitting}
                >
                    Salva
                </AppButton>
            </div>
        </form>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Shop.propTypes = {
    className: PropTypes.string.isRequired,
}

_Shop.defaultProps = {}

// ----------------------------------------------------------------------------

const Shop = styled(_Shop)`
    & {
        .drag-help-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 600px;
            padding: 10px 5px;
            border-radius: 8px;
            margin-bottom: 20px;
            .drag-help {
                display: block;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
            }

            svg {
                color: ${({ theme }) => theme.colors.success};
                margin-top: 3px;
                margin-right: 10px;
                font-size: 22px;
            }
        }
        .add-button {
            margin-bottom: 20px;
            display: block;
        }
        .buttons-row {
            button {
                height: 48px;
                margin-right: 10px;
            }
        }
        .widgets {
            .widget {
                border-radius: 14px;
                width: 600px;
                border: 2px solid ${({ theme }) => theme.colors.primary};
                margin-bottom: 20px;
                padding: 20px 10px;
                .widget-trigger {
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    .widget-trigger-title {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .widget-trigger-icon {
                        font-weight: 600;
                        font-size: 22px;
                        transition: all 200ms ease;
                    }
                }

                .widget-content {
                    .hero-editor {
                        margin-top: 20px;
                    }
                }
                &.widget-open {
                    .widget-trigger .widget-trigger-icon {
                        transform: rotate(180deg);
                    }
                }
                &.widget-static {
                    background-color: ${({ theme }) =>
                        theme.colors.tabBarInactiveColor};
                    border: none !important;
                }
                &.widget-dynamic {
                    .widget-trigger {
                        cursor: pointer;
                    }
                }
            }
        }

        .item {
            border-radius: 14px;

            border: 2px solid ${({ theme }) => theme.colors.primary};
            margin-bottom: 20px;
            padding: 20px 10px;
            .item-trigger {
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .item-trigger-title {
                    font-size: 16px;
                    font-weight: 600;
                }
                .item-trigger-icon {
                    font-weight: 600;
                    font-size: 22px;
                    transition: all 200ms ease;
                }
            }

            .item-content {
                .hero-editor {
                    margin-top: 20px;
                }
            }
            &.item-open {
                .widget-trigger .widget-trigger-icon {
                    transform: rotate(180deg);
                }
            }
            &.item-static {
                background-color: ${({ theme }) =>
                    theme.colors.tabBarInactiveColor};
                border: none !important;
            }
            &.item-dynamic {
                .item-trigger {
                    cursor: pointer;
                }
            }
        }

        .buttons-row {
            margin-top: 30px;
        }
    }
`
// ----------------------------------------------------------------------------

const getNewWidget = () => ({
    type: "dynamic",
    id: uid(20),
    title: "Nuovo widget",
    subtitle: "",
    view: null,
    targetType: null,
    target: null,
    image: null,
})

const getNewItem = () => ({
    type: "slider",
    id: uid(20),
    title: "Nuovo widget",
    targetType: null,
    target: null,
    image: null,
    visible: true,
})

const getNewSliderWidget = () => ({
    subType: "sliderWidget",
    title: "Slider widget",
    id: uid(20),
    content: [getNewItem()],
})

export default Shop
