import PropTypes from "prop-types"
import React from "react"
import { HashRouter as Router } from "react-router-dom"
import styled from "styled-components"

import { useStateValue } from "../_shared/context/AppStateStore"
import AuthPage from "./AuthPage"
import LoggedApp from "./LoggedNav"

// ----------------------------------------------------------------------------

function _RoutedApp(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [{ user }] = useStateValue()

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return <Router>{user ? <LoggedApp /> : <AuthPage />}</Router>
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_RoutedApp.propTypes = {
    className: PropTypes.string.isRequired,
}

_RoutedApp.defaultProps = {}

// ----------------------------------------------------------------------------

const RoutedApp = styled(_RoutedApp)`
    & {
    }
`
// ----------------------------------------------------------------------------

export default RoutedApp
