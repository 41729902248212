import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import { HiEye, HiEyeSlash, HiPencil, HiTrash } from "react-icons/hi2"

import AppButton from "../../../../../_shared/components/AppButton"
import AppInput from "../../../../../_shared/components/FormItems/AppInput"
import { Modal } from "antd"

// ----------------------------------------------------------------------------

function _StaticEditor(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const { className, values, onChange, isNew, edit, onDelete } = props

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------
    const [isModalOpen, setIsModalOpen] = useState(false)

    function handleCloseModal() {
        onChange("openEdit")(false)
        onChange("new")(false)
        setIsModalOpen(false)
    }

    return (
        <div className={`${className}`}>
            <AppButton onClick={() => onDelete(values)} className={"button"}>
                <HiTrash style={{ fontSize: 24, color: "#000" }} />
            </AppButton>
            <AppButton
                onClick={() => setIsModalOpen(true)}
                className={"button"}
            >
                {edit ? (
                    <HiPencil style={{ fontSize: 24, color: "#000" }} />
                ) : (
                    <></>
                )}
            </AppButton>
            <AppButton
                onClick={() => onChange("visible")(!values?.visible)}
                className={
                    values?.visible === true ? "visibile-button" : "button"
                }
            >
                {values?.visible === true ? (
                    <HiEye style={{ fontSize: 24, color: "#000" }} />
                ) : (
                    <HiEyeSlash style={{ fontSize: 24, color: "#000" }} />
                )}
            </AppButton>

            <Modal
                open={isModalOpen}
                onOk={() => handleCloseModal()}
                onCancel={() => setIsModalOpen(false)}
                className="row"
            >
                <AppInput
                    className="input"
                    value={values?.title}
                    onChange={onChange("title")}
                    label="Titolo"
                />
                <AppInput
                    className="input"
                    value={values?.key}
                    onChange={onChange("key")}
                    label="Identificativo"
                />
            </Modal>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_StaticEditor.propTypes = {
    className: PropTypes.string.isRequired,
    values: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    new: PropTypes.string,
}

_StaticEditor.defaultProps = {}

// ----------------------------------------------------------------------------

const HeroEditor = styled(_StaticEditor)`
    & {
        .button {
            height: 25px;
            width: 30px;
            background-color: transparent;
        }
        .visibile-button {
            height: 25px;
            width: 30px;
            background-color: transparent;
        }
        .row {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
        .input {
            margin-bottom: 20px;
            max-width: 300px;
            margin: 20px;
        }
    }
`
// ----------------------------------------------------------------------------

export default HeroEditor
