import React, { useState } from "react"
import { message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"

// ----------------------------------------------------------------------------

function _StoriesVideo(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={`${className}`}>
            <h1 className="page-title">StoriesVideo</h1>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_StoriesVideo.propTypes = {
    className: PropTypes.string.isRequired,
}

_StoriesVideo.defaultProps = {}

// ----------------------------------------------------------------------------

const StoriesVideo = styled(_StoriesVideo)`
    & {
        .stats-container {
            margin: 20px 0;
            padding: 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .available-for-list {
            span {
                display: block;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StoriesVideo

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
            render: (published) =>
                published && <Tag color="green">PUBBLICATA</Tag>,
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
