import "./App.css"

import PropTypes from "prop-types"
import React from "react"
import { ThemeProvider, createGlobalStyle } from "styled-components"

import RoutedApp from "./RoutedApp"
import colors from "./_shared/constants/colors"
import { AppStateStore } from "./_shared/context/AppStateStore"

// ----------------------------------------------------------------------------

function App() {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const initialState = {
        user: null,
        colors: "dark",
    }

    const reducer = (state, action) => {
        switch (action.type) {
            case "SIGN_IN_FULFILLED": {
                return {
                    ...state,
                    token: action.payload.token,
                    user: action.payload.user,
                }
            }

            case "SIGN_OUT": {
                return {
                    ...state,
                    user: null,
                    token: null,
                }
            }

            default: {
                return {
                    ...state,
                }
            }
        }
    }

    const theme = {
        colors,
    }

    const GlobalStyle = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: Ibm;
    }
    a {
      color: inherit;
      text-decoration: none;
    }   
    :root {
      --antd-wave-shadow-color: #3943A8;
     }
    /* .ant-btn {
      background: ${colors.primary};
      border-color: ${colors.primary};
    } */
    body {
      width: 100vw;
      height: 100vh;
      overflow-x: hidden;
    }
`

    return (
        <ThemeProvider theme={theme}>
            <AppStateStore
                initialState={initialState}
                reducer={reducer}
                storeKey="beautystar.cms"
            >
                <GlobalStyle />
                <RoutedApp />
            </AppStateStore>
        </ThemeProvider>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

App.propTypes = {
    className: PropTypes.string.isRequired,
}

App.defaultProps = {}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

export default App
