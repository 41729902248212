import { Popconfirm as AntdPopconfirm } from "antd"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import styled from "styled-components"

//------------------------------------------------------------------------------

function Popconfirm(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { children, ...popconfirmProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    return (
        <div className={props.className}>
            <AntdPopconfirm className="popconfirm" {...popconfirmProps}>
                {children}
            </AntdPopconfirm>
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Popconfirm.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

Popconfirm.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledPopconfirm = styled(Popconfirm)`
    & {
        .ant-popconfirm {
            min-width: 200px;
        }
        .ant-btn-primary {
            border-color: ${(props) => props.theme.colors.primaryColor};
            background-color: ${(props) => props.theme.colors.primaryColor};
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledPopconfirm
