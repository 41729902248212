import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { message, Badge } from "antd"
import dayjs from "dayjs"

import * as api from "../../../../api/Local"
import AppButton from "../../../_shared/components/AppButton"
import AppInput from "../../../_shared/components/FormItems/AppInput"
import Table from "../../../_shared/components/Table"
import EditButton from "../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../_shared/components/Buttons/DeleteButton"
import { useStateValue } from "../../../_shared/context/AppStateStore"
import usePaginatedRestResource from "../../../_shared/hooks/usePaginatedRestResource"

import StoreFormBottomSheet from "./StoreFormBottomSheet"

// ----------------------------------------------------------------------------

function Stores(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { className } = props

    // -------------------------------------
    // Hooks (e.g. useState, ...)
    // -------------------------------------

    const [{ token }] = useStateValue()

    const [
        requests,
        fetchStores,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource("/stores", token)

    console.log("pagination: ", pagination)

    const [stores] = usePaginatedRestResource("/storeden/stores", token)

    const [selectedStore, setSelectedStore] = useState(null)

    // -------------------------------------
    // Memoized values
    // -------------------------------------

    // -------------------------------------
    // Effects
    // -------------------------------------

    // -------------------------------------
    // Component functions
    // -------------------------------------

    async function handleCreateStore(post) {
        try {
            let body = {
                ...post,
            }

            if (post.image) {
                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.createResource("/posts", token, body)
            setSelectedStore(null)
            fetchStores()
            message.success("Articolo creato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    async function handleUpdateStore(post) {
        try {
            let body = {
                ...post,
            }

            if (post.image && typeof post.image !== "string") {
                const oldImage = requests?.find(
                    (item) => item?.id === post.id
                )?.image

                const imageId = oldImage?.substring?.(
                    oldImage?.lastIndexOf("/") + 1
                )

                await api.deleteStoredenFile(imageId, token)

                const uploadResponse = await api.uploadFile(post.image)

                body.image = uploadResponse?.url ?? body.image
            }

            await api.updateResource(`/posts`, token, selectedStore.id, body)
            setSelectedStore(null)
            fetchStores()
            message.success("Articolo aggiornato con successo")
        } catch (error) {
            console.error(error)
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleStoreBottomSheetConfirm(post) {
        if (post.id) {
            return handleUpdateStore(post)
        } else {
            return handleCreateStore(post)
        }
    }

    async function handleDeleteStore(post) {
        try {
            if (!post?.id) {
                return
            }

            await api.deleteResource("/posts", token, post.id)
            fetchStores()

            message.success("Articolo eliminato con successo")
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function handleStoreBottomSheetCancel() {
        setSelectedStore(null)
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => setSelectedStore(row)} />

                {/* <Popconfirm
                    onConfirm={() => handleDeleteStore(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo articolo?"
                >
                    <DeleteButton />
                </Popconfirm> */}
            </div>
        )
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const defaultStore = {
        published: false,
    }

    return (
        <div className={`${className}`}>
            <h1 className="page-title">Stores</h1>

            {/* <AppButton
                uxType="secondary"
                onClick={() => setSelectedStore(defaultStore)}
            >
                Nuovo articolo
            </AppButton> */}

            <Table
                dataSource={requests}
                pagination={{ ...pagination, total: totalRecordsNumber }}
                onChange={(pagination) => fetchStores({ pagination })}
                columns={getColumns(renderActions)}
                loading={loading}
            />

            <StoreFormBottomSheet
                open={selectedStore ? true : false}
                post={selectedStore}
                onCancel={handleStoreBottomSheetCancel}
                onConfirm={handleStoreBottomSheetConfirm}
            />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

Stores.propTypes = {
    className: PropTypes.string.isRequired,
}

Stores.defaultProps = {}

// ----------------------------------------------------------------------------

const StyledStores = styled(Stores)`
    & {
        .actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledStores

function getColumns(renderActions) {
    return [
        {
            title: "",
            key: "disabled",
            dataIndex: "disabled",
            // eslint-disable-next-line
            render: (disabled) => disabled ? <Badge status="error" /> : <Badge status="success" />
        },
        {
            title: "Nome",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Codice",
            key: "code",
            dataIndex: "code",
        },
        {
            title: "Indirizzo",
            key: "address",
            dataIndex: "address",
        },

        {
            key: "actions",
            render: renderActions,
        },
    ]
}
