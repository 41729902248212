import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { SketchPicker } from "react-color"
import styled from "styled-components"

import AppButton from "../AppButton"

//------------------------------------------------------------------------------

function ColorPickerPopoverTag(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { color, onSave, onChange } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const [isPickerVisible, toggleColorPicker] = useState(false)

    const [tempColor, setTempColor] = useState(null)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        setTempColor(null)
    }, [color])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleTagClick() {
        toggleColorPicker(!isPickerVisible)
    }

    function handleColorChange(color) {
        setTempColor(color)

        onChange && onChange(color)
    }

    function handleClick() {
        toggleColorPicker(null)
        setTempColor(null)
    }

    function handleSave() {
        onSave && onSave(tempColor)

        toggleColorPicker(null)
        setTempColor(null)
    }

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const currentColor = (tempColor && tempColor.hex) || color
    return (
        <div className={props.className}>
            <div
                className="tag"
                style={{ backgroundColor: currentColor }}
                onClick={handleTagClick}
            />
            {isPickerVisible && (
                <div className="popover-container">
                    <SketchPicker
                        color={currentColor}
                        onChange={handleColorChange}
                    />

                    {onSave && (
                        <div className="btns-container">
                            <AppButton
                                className="btn"
                                uxType="delete"
                                onClick={handleClick}
                            >
                                Annulla
                            </AppButton>
                            <AppButton
                                uxType="primary"
                                className="btn"
                                onClick={handleSave}
                            >
                                Salva
                            </AppButton>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

ColorPickerPopoverTag.propTypes = {
    className: PropTypes.string.isRequired,
    color: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
}

ColorPickerPopoverTag.defaultProps = {
    color: "#f5f5f5",
    onChange: () => {},
}

// ----------------------------------------------------------------------------

const StyledColorPickerPopoverTag = styled(ColorPickerPopoverTag)`
    & {
        position: relative;
        width: 48px;

        .tag {
            width: 48px;
            height: 24px;
            border-radius: 4px;
        }

        .popover-container {
            position: absolute;
            top: calc(100% + 5px);
            right: 0;

            z-index: 999;

            padding: 10px 10px 0px;
            box-sizing: initial;
            background: rgb(255, 255, 255);
            border-radius: 4px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0.15) 0px 8px 16px;

            .sketch-picker {
                padding: 0 !important;

                width: 230px !important;
                border-radius: 0 !important;
                border: none !important;
                box-shadow: none !important;
            }

            .btns-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                margin: 12px 0;
                .btn {
                    height: 35px;
                    padding: 0px 10px;
                    font-size: 14px;
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default StyledColorPickerPopoverTag
