import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import styled from "styled-components"

import Switch from "../../../../_shared/components/AppSwitch"
import BottomSheet from "../../../../_shared/components/BottomSheet"
import Input from "../../../../_shared/components/FormItems/AppInput"
import ImageUploadInput from "../../../../_shared/components/FormItems/ImageUploadInput"

import AppDatePicker from "../../../../_shared/components/FormItems/AppDatePicker"
import WYSIWYG from "../../../../_shared/components/FormItems/WYSIWYG"
import useForm from "../../../../_shared/hooks/useForm"
import AppSelect from "../../../../_shared/components/FormItems/AppSelect"
import AppInput from "../../../../_shared/components/FormItems/AppInput"
import useRestTable from "../../../../_shared/hooks/usePaginatedRestResource"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import AppButton from "../../../../_shared/components/AppButton"

// ----------------------------------------------------------------------------

function CategoriesBottomSheet(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------

    const { open, category, onConfirm, ...sheetProps } = props

    // -------------------------------------
    // Hooks (e.g. useState, useMemo ...)
    // -------------------------------------

    const initialValues = category ?? {}

    const [formValues, setFormValue, setFormValues, clearForm] =
        useForm(initialValues)

    const [{ token }] = useStateValue()
    const [filters] = useRestTable("/storeden/filters", token)
    // const [worlds] = useRestTable("/worlds")
    const [storedenCategories] = useRestTable("/storeden/categories", token)
    const [brands] = useRestTable("/storeden/brands", token)

    console.log("form values : ", formValues)

    // -------------------------------------
    // Effects
    // -------------------------------------

    useEffect(() => {
        if (open) {
            setFormValues(category ?? {})
        } else {
            clearForm()
        }
    }, [open])

    // -------------------------------------
    // Component functions
    // -------------------------------------

    function handleFormValueChange(key) {
        return function (value) {
            setFormValue(key, value)
        }
    }

    function handleConfirm() {
        onConfirm?.(formValues)
    }

    const destinationOptions = [
        { label: "Categoria", value: "category" },
        { label: "Brand", value: "brand" },
        { label: "Editoriale", value: "editorial" },
        { label: "Filtro", value: "filter" },
        { label: "Tag", value: "tag" },
    ]

    // -------------------------------------
    // Component local variables
    // -------------------------------------

    const brandOptions = useMemo(() => {
        return brands?.map((brand) => ({ label: brand.name, value: brand.uid }))
    }, [brands])

    const categoryOptions = useMemo(() => {
        const options = []

        storedenCategories
            // ?.filter((c) => {
            //     return worlds?.some(
            //         (w) => String(w.storedenId) === String(c.parentUID)
            //     )
            // })
            ?.forEach((c) => {
                // const world = worlds?.find(
                //     (w) => String(w.storedenId) === String(c.parentUID)
                // )
                options.push({
                    label: ` ${c.name}`,
                    value: c.uid,
                })

                storedenCategories
                    ?.filter((category) => {
                        return category.parentUID === c.uid
                    })
                    ?.map((tier3Category) => {
                        options.push({
                            label: `${c.name} >> ${tier3Category.name}`,
                            value: tier3Category.uid,
                        })
                    })
            })

        options.sort((a, b) => a.label.localeCompare(b.label))

        return options
    }, [storedenCategories])

    function renderTargetTypeSelector() {
        switch (formValues?.targetType) {
            case "editorial": {
                return (
                    <AppInput
                        label="Url editoriale"
                        value={formValues?.target}
                        onChange={handleFormValueChange("target")}
                        className="input"
                    ></AppInput>
                )
            }

            case "tag": {
                return (
                    <AppInput
                        label="Tag"
                        value={formValues?.target}
                        onChange={handleFormValueChange("target")}
                        className="input"
                    ></AppInput>
                )
            }

            default: {
                return (
                    <AppSelect
                        value={formValues?.target}
                        onChange={handleFormValueChange("target")}
                        options={getTargetOptions()}
                        filterOption={getTargetFilterOption()}
                        className="input"
                        placeholder="Seleziona target"
                        label="Target"
                    />
                )
            }
        }
    }

    function getTargetOptions() {
        switch (formValues?.targetType) {
            case "category":
                return categoryOptions
            case "editorial":
                return []
            case "brand":
                return brandOptions

            default:
                return []
        }
    }

    function getTargetFilterOption() {
        if (formValues?.targetType === "brand") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        if (formValues?.targetType === "category") {
            return (input, option) => {
                const pat = new RegExp(input?.toLowerCase())
                return pat.test(option?.children?.toLowerCase())
            }
        }
        return undefined
    }

    return (
        <BottomSheet
            className={props.className}
            open={open}
            snapPoint={80}
            title={category?.id ? "Modifica categoria" : "Nuovo categoria"}
            onConfirm={handleConfirm}
            {...sheetProps}
        >
            <Input
                className="form-control"
                label="Nome"
                value={formValues.name}
                onChange={handleFormValueChange("name")}
            />

            <ImageUploadInput
                label="Immagine"
                image={formValues.image}
                onUpload={handleFormValueChange("image")}
            />

            <AppSelect
                value={formValues?.targetType}
                onChange={handleFormValueChange("targetType")}
                options={destinationOptions}
                className="input"
                placeholder="Seleziona categoria"
                label="Categoria"
            />

            {renderTargetTypeSelector()}
        </BottomSheet>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

CategoriesBottomSheet.propTypes = {
    className: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    post: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
}

CategoriesBottomSheet.defaultProps = {}

// ----------------------------------------------------------------------------

const CategoriesBottomSheetWithStyle = styled(CategoriesBottomSheet)`
    & {
        .sheet-content {
            max-width: 500px;

            .form-control {
                margin-bottom: 20px;
                width: 500px;
                max-width: 500px;
            }
        }
    }
`
// ----------------------------------------------------------------------------

export default CategoriesBottomSheetWithStyle
