import React, { useEffect, useMemo, useState } from "react"
import { Popconfirm, Table, message } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Tag, Statistic, Card } from "antd"
import { useStateValue } from "../../../../_shared/context/AppStateStore"
import useRestTable from "../../../../_shared/hooks/usePaginatedRestResource"
import BannerBottomSheet from "./BannerBottomSheet"
import AppButton from "../../../../_shared/components/AppButton"
import * as api from "../../../../../api/Local"
import usePaginatedRestResource from "../../../../_shared/hooks/usePaginatedRestResource"
import EditButton from "../../../../_shared/components/Buttons/EditButton"
import DeleteButton from "../../../../_shared/components/Buttons/DeleteButton"

// ----------------------------------------------------------------------------

function _Banners(props) {
    // -------------------------------------
    // Props destructuring
    // -------------------------------------
    const [{ token }] = useStateValue()

    //  console.log("banner called : ", props)

    const slug = props?.location?.pathname.includes("totem") ? getSlug() : ""
    console.log("slug : ", slug)
    function getSlug() {
        const splitArray = props?.location?.pathname.split("/")
        return splitArray[splitArray?.length - 2]
    }

    const [
        banners,
        fetchBanners,
        loading,
        pagination,
        totalRecordsNumber,
        onFiltersChange,
    ] = usePaginatedRestResource(`totemstore/${slug}/banners`, token)

    useEffect(() => {
        fetchBanners()
    }, [])
    const [selectedItem, setSelectedItem] = useState()

    const { className } = props

    async function handleConfirm(formValues) {
        try {
            if (formValues.image) {
                if (typeof formValues.image === "object") {
                    const uploadResponse = await api.uploadFile(
                        formValues.image
                    )

                    formValues.image = uploadResponse?.url ?? formValues.image
                }
            }
            formValues.storeId = slug

            const newBanner = formValues?.id
                ? await api.updateBanner(formValues?.id, formValues, token)
                : await api.createBanner(formValues, token)

            const storeView = await api.getTotemStore(token, slug)

            const newStoreView = {
                ...storeView,
                banners: [...(storeView?.banners ?? []), newBanner?.id],
            }

            await api.updateTotemStore(slug, newStoreView, token)
            fetchBanners()
            message.success("Operazione completata con successo")
            setSelectedItem(null)
        } catch (e) {
            console.log(e)
            message.error("L'operazione non è andata a buon fine")
        }
    }

    function handleCancel() {
        setSelectedItem(null)
    }

    async function handleDeleteBanner(brand) {
        try {
            if (!brand?.id) {
                return
            }

            await api.deleteBanner(brand.id, token)
            // fetchBanners()

            message.success("Brand eliminato con successo")
        } catch (error) {
            message.error("Si è verificato un errore. Riprovare più tardi")
        }
    }

    function renderActions(row) {
        return (
            <div className="actions">
                <EditButton onClick={() => setSelectedItem(row)} />

                <Popconfirm
                    onConfirm={() => handleDeleteBanner(row)}
                    placement="left"
                    title="Sei sicuro di voler eliminare questo banner?"
                >
                    <DeleteButton />
                </Popconfirm>
            </div>
        )
    }
    console.log("banners: ", banners)
    return (
        <div className={`${className}`}>
            <h1 className="page-title">Banners</h1>
            <AppButton uxType="secondary" onClick={() => setSelectedItem({})}>
                Nuovo banner
            </AppButton>
            <BannerBottomSheet
                defaultValues={selectedItem}
                open={!!selectedItem}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            ></BannerBottomSheet>

            <Table dataSource={banners} columns={getColumns(renderActions)} />
        </div>
    )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

_Banners.propTypes = {
    className: PropTypes.string.isRequired,
}

_Banners.defaultProps = {}

// ----------------------------------------------------------------------------

const Banners = styled(_Banners)`
    & {
        .stats-container {
            margin: 20px 0;
            padding: 20px;
            border-radius: 8px;
            background-color: #f5f5f5;
        }
        .available-for-list {
            span {
                display: block;
            }
        }
        .actions {
            flex-direction: "row";
            display: flex;
        }
    }
`
// ----------------------------------------------------------------------------

export default Banners

function getColumns(renderActions) {
    return [
        {
            key: "title",
            title: "Titolo",
            dataIndex: "title",
        },
        {
            key: "body",
            title: "Testo",
            dataIndex: "body",
        },
        {
            key: "published",
            title: "",
            dataIndex: "published",
            //eslint-disable-next-line
        },
        {
            key: "actions",
            render: renderActions,
        },
    ]
}
